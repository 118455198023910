import React from 'react';
import {
    ArrayInput,
    BooleanInput,
    Create,
    FormTab,
    NumberInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';
import Utils from '../../lib/Utils';

const validateField = required();

const ConcessionCreate = props => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="General infos">
                <TextInput source="nom" validate={validateField} />
                <TextInput source="villeRattachement" validate={validateField} />
                <TextInput source="emailContact" type="email" validate={validateField} />
                <TextInput source="phoneContactGeneral" validate={validateField} />
                <TextInput source="nameContactAnnonce" />
                <TextInput source="phoneContactAnnonce" validate={validateField} />
                <NumberInput source="geoPosition.latitude" validate={validateField} />
                <NumberInput source="geoPosition.longitude" validate={validateField} />
                <BooleanInput source="useFunding" />
            </FormTab>
            <FormTab label="Adresse">
                <TextInput source="adresse.address1" validate={validateField} />
                <TextInput source="adresse.address2" />
                <TextInput source="adresse.postCode" validate={validateField} />
                <TextInput source="adresse.city" validate={validateField} />
            </FormTab>
            <FormTab label="Contact">
                <ArrayInput source="destinatairesFormAchat.to" validate={Utils.validateRequiredStringArray}>
                    <SimpleFormIterator validate={validateField}>
                        <TextInput format={Utils.formatRecordStringArrayInput} label="resources.concessions.fields.email" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="destinatairesFormAchat.cc">
                    <SimpleFormIterator>
                        <TextInput format={Utils.formatRecordStringArrayInput} label="resources.concessions.fields.email" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Create>
);

export default ConcessionCreate;
