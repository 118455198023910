import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import compose from 'recompose/compose';

const downloadCsvModel = (linkRef, type, fields) => {
    if (!linkRef || !linkRef.current) {
        return;
    }

    const link = linkRef.current;
    link.href = `data:text/csv;charset=utf-8,${encodeURI(fields.map(({ column }) => column).join(';') + "\n\n")}`;
    link.download = `import-${type}.csv`;
    link.click();
};

const ImportsFieldsTable = ({ translate, type, fields }) => {
    const linkRef = useRef(null);

    return (
        <Grid container>
            <Grid item xs={6}>
                <p>{translate('app.imports.common.format_to_respect')}</p>
            </Grid>
            <Grid item xs={6}>
                <a target="_blank" ref={linkRef} style={{ display: "none" }} />
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => downloadCsvModel(linkRef, type, fields)}
                >
                    Télécharger un modèle
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('app.imports.common.table.column')}</TableCell>
                            <TableCell>{translate('app.imports.common.table.description')}</TableCell>
                            <TableCell>{translate('app.imports.common.table.required')}</TableCell>
                            <TableCell>{translate('app.imports.common.table.specificity')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map(({ column, description, required, specificity }, i) => (
                            <TableRow key={i}>
                                <TableCell>{column}</TableCell>
                                <TableCell>{description}</TableCell>
                                <TableCell>{required ? <CheckIcon /> : null}</TableCell>
                                <TableCell>{specificity}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

ImportsFieldsTable.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        column: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        specificity: PropTypes.node,
    })).isRequired,
};

const enhance = compose(
    translate
);

export default enhance(ImportsFieldsTable);
