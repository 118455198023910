import React, { Component } from 'react';
import {
    Datagrid,
    DateField,
    EditButton,
    Filter,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    translate,
} from 'react-admin';
import PublishedField from '../../components/PublishedField';
import CustomPagination from '../../Theming/CustomElements/CustomPagination';
import ModelOptionField from '../../components/ModelOptionField';

const AdFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={999} source="profil" reference="profiles" sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput perPage={999} source="marque" reference="marques" sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput perPage={999} source="modele" reference="modeles" sort={{ field: 'reference', order: 'ASC' }} allowEmpty>
            <SelectInput optionText={<ModelOptionField />} />
        </ReferenceInput>
        <ReferenceInput perPage={999} source="concession" reference="concessions" sort={{ field: 'nom', order: 'ASC' }} allowEmpty>
            <SelectInput optionText="nom" />
        </ReferenceInput>
        <SelectInput source="status" choices={[
            { id: 'draft', name: 'app.ads.status.draft' },
            { id: 'published', name: 'app.ads.status.published' },
            { id: 'refused', name: 'app.ads.status.refused' },
            { id: 'archived', name: 'app.ads.status.archived' },
            { id: 'pending', name: 'app.ads.status.pending' },
            { id: 'unpublished', name: 'app.ads.status.unpublished' },
        ]} />
    </Filter>
);

class AdList extends Component {
    render() {
        const { translate: t, permissions, ...props } = this.props;

        return (
            <List perPage={25} pagination={<CustomPagination />} exporter={false} filters={<AdFilter />} {...props}>
                <Datagrid>
                    <ReferenceField source="profil" reference="profiles" linkType={false} sortable={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="marque" reference="marques" linkType={false} sortable={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="modele" reference="modeles" linkType={false} sortable={false}>
                        <ModelOptionField />
                    </ReferenceField>
                    <TextField source="annee" label={'app.ads.moderation.annee'} />
                    <ReferenceField source="concession" reference="concessions" linkType={false} allowEmpty sortable={false}>
                        <TextField source="nom" />
                    </ReferenceField>
                    <TextField source="reference" />
                    <NumberField source="prix" />
                    <NumberField source="kilometrage" />
                    <PublishedField source="status" />
                    <DateField source="publishDate" />
                    <EditButton />
                </Datagrid>
            </List>
        );
    }
}

export default translate(AdList);
