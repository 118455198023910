import React from 'react';
import {Datagrid, EditButton, List, TextField, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import SimpleArray from "../../Theming/CustomElements/SimpleArray";
import CustomPagination from "../../Theming/CustomElements/CustomPagination";

const UserList = props => (
    <List exporter={false} perPage={25} pagination={<CustomPagination />} {...props}>
        <Datagrid rowClick="edit">
            <SimpleArray source="roles" sortable={false} label={"app.roles.label"}/>
            <ReferenceArrayField source="concessions"  reference="concessions" sortable={false}>
                <SingleFieldList>
                    <ChipField source="nom" key="nom" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="email"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export default UserList;
