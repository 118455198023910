import React from 'react';
import {Datagrid, EditButton, List, TextField, ImageField} from 'react-admin';
import CustomPagination from "../../Theming/CustomElements/CustomPagination";

const AmenagementList = props => (
    <List perPage={25}  pagination={<CustomPagination />} exporter={false} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <ImageField source="media.src" title="media.title" label="app.media.image"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export default AmenagementList;
