import React from 'react';
import { Query } from 'react-admin';

const ModelOptionField = ({ record }) => {
    if (!record.finition) {
        return <span>{record.reference}</span>;
    }

    return (
        <Query type="GET_ONE" resource="finition" payload={{ id: record.finition }}>
            {({ data, loading, error }) => {
                if (loading) {
                    return <span>{record.reference} ...</span>;
                }

                if (error) {
                    return <span>{record.reference}</span>;
                }

                return <span>{record.reference} {data.name}</span>;
            }}
        </Query>
    );
};

export default ModelOptionField;
