/*
 * Configurations per environment (= per hostname).
 *
 * siteCodes: All available sites codes
 * For each declared site code, we need an equivalent in apiBaseUri config
 */

export default {
    'default': {
        siteCodes: ['AVIS_FRANCE', 'AVIS_ENGLAND'],
        apiBaseUri: {
            AVIS_FRANCE: 'https://127.0.0.1:8000/api',
            AVIS_ENGLAND: 'https://avis-en.wip/api',
        }
    },
    'avis-admin.umanit.fr': {
        siteCodes: ['AVIS_FRANCE'],
        apiBaseUri: {
            AVIS_FRANCE: 'http://avis.umanit.fr/api',
        },
    },
    'bo-occasions.pilote.fr': {
        siteCodes: ['AVIS_FRANCE'],
        apiBaseUri: {
            AVIS_FRANCE: 'https://occasions.pilote.fr/api',
        },
    },
};
