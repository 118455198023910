import React from 'react';

export const list = [{
    label: 'app.imports.btn.users',
    type: 'users',
}, {
    label: 'app.imports.btn.concessions',
    type: 'concessions',
}, {
    label: 'app.imports.btn.options',
    type: 'options',
}, {
    label: 'app.imports.btn.models',
    type: 'models',
}];

export const configuration = {
    users: {
        title: 'app.imports.users.title',
        fields: [{
            column: 'email',
            description: 'Identifiant unique du compte',
            required: true,
            specificity: 'Si existant, mise à jour de compte, sinon création',
        }, {
            column: 'role',
            description: 'Rôle du compte',
            required: true,
            specificity: <>Doit valoir <strong>ROLE_ADMIN</strong> ou <strong>ROLE_CONCESSIONNAIRE</strong></>,
        }, {
            column: 'active',
            description: 'Indique si le compte est actif ou non',
            required: true,
            specificity: <><strong>1</strong> = compte actif ; <strong>0</strong> = compte inactif</>,
        }, {
            column: 'password',
            description: 'Mot de passe du compte',
            specificity: 'Pris en compte seulement pour la création de compte',
        }, {
            column: 'concessions',
            description: 'Concessions de l\'utilisateur',
            specificity: <>Liste des concessions séparées par des virgules : <strong>Avis Car-Away | Marseille,Avis Car-Away | Ajaccio</strong></>,
        }]
    },
    concessions: {
        title: 'app.imports.concessions.title',
        fields: [{
            column: 'nom',
            description: 'Nom de la concession',
            required: true,
            specificity: 'Si existant, mise à jour de la concession, sinon création',
        }, {
            column: 'villeRattachement',
            description: 'Ville de rattachement',
            required: true,
        }, {
            column: 'emailContact',
            description: 'Email de contact de la concession',
            required: true,
        }, {
            column: 'phoneContactGeneral',
            description: 'Téléphone de contact de la concession',
            required: true,
        }, {
            column: 'nameContactAnnonce',
            description: 'Nom de l\'interlocuteur de contact pour les annonces',
        }, {
            column: 'phoneContactAnnonce',
            description: 'Téléphone de l\'interlocuteur de contact pour les annonces',
            required: true,
        }, {
            column: 'geoPosition.latitude',
            description: 'Latitude GPS de la concession',
            required: true,
        }, {
            column: 'geoPosition.longitude',
            description: 'Longitude GPS de la concession',
            required: true,
        }, {
            column: 'useFunding',
            description: 'Proposer ou non l\'activation du module de financement sur les annonces',
            required: true,
            specificity: <>
                <strong>1</strong> = proposera l'activation ; <strong>0</strong> = ne proposera pas l'activation</>,
        }, {
            column: 'adresse.address1',
            description: 'Adresse de la concession',
            required: true,
        }, {
            column: 'adresse.address2',
            description: 'Complément d\'adresse de la concession',
        }, {
            column: 'adresse.postCode',
            description: 'Code postal de la concession',
            required: true,
        }, {
            column: 'adresse.city',
            description: 'Ville de la concession',
            required: true,
        }, {
            column: 'destinatairesFormAchat.to',
            description: 'Destinataires directs des formulaires de demande d\'achat',
            required: true,
            specificity: <>Liste d'emails séparés par des virgules : <strong>john.doe@domain.tld,jane.doe@domain.tld</strong></>
        }, {
            column: 'destinatairesFormAchat.cc',
            description: 'Destinataires en copie des formulaires de demande d\'achat',
            specificity: <>Liste d'emails séparés par des virgules : <strong>john.doe@domain.tld,jane.doe@domain.tld</strong></>
        }],
    },
    options: {
        title: 'app.imports.options.title',
        fields: [{
            column: 'category',
            description: 'Catégorie de l\'option',
            required: true,
            specificity: 'La catégorie doit déjà exister',
        }, {
            column: 'name',
            description: 'Nom de l\'option',
            required: true,
            specificity: 'Il n\'y aura que de la création, pas de mise à jour',
        }],
    },
    models: {
        title: 'app.imports.models.title',
        fields: [{
            column: 'reference',
            description: 'Référence du modèle',
            required: true,
            specificity: 'Si existant, mise à jour du modèle, sinon création',
        }, {
            column: 'marque',
            description: 'Nom de la marque du modèle',
            required: true,
            specificity: 'La marque doit déjà exister',
        }, {
            column: 'profil',
            description: 'Profil du modèle',
            required: true,
            specificity: 'Le profil doit déjà exister',
        }, {
            column: 'usage',
            description: 'Usage du modèle',
            specificity: 'L\'usage doit déjà exister',
        }, {
            column: 'finition',
            description: 'Finition du modèle',
            specificity: 'La finition doit déjà exister. Attention à la concordance entre marque <> profil <> finition.',
        }, {
            column: 'nbPlaceCarteGrise',
            description: 'Nombre de places Carte grise',
            required: true,
        }, {
            column: 'nbPlaceCouchage',
            description: 'Nombre de places de couchage',
            required: true,
        }, {
            column: 'nbPlaceRepas',
            description: 'Nombre de places de repas',
            required: true,
        }, {
            column: 'chargeUtile',
            description: 'Charge utile',
        }, {
            column: 'masseMaxi',
            description: 'Masse maxi',
        }, {
            column: 'longueur',
            description: 'Longueur du modèle',
        }, {
            column: 'largeur',
            description: 'Largeur du modèle',
        }, {
            column: 'hauteur',
            description: 'Hauteur du modèle',
        }, {
            column: 'urlVisite360',
            description: 'URL de la visite 360',
        }],
    },
};
