import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import MessageIcon from '@material-ui/icons/Message';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {translate} from 'react-admin';
import CardIcon from './CardIcon';
import CircularProgress from "@material-ui/core/CircularProgress";


const styles = {
    main: {
        flex: '1',
        marginLeft: '1em',

        marginTop: 20,
        cursor: 'pointer',
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    alertBold: {
        fontWeight: '600',
    }
};

const DashboardCard = ({value, translate, classes}) => (
    <div className={classes.main} onClick={() => window.location.hash = "#/achat_form_results"}>
        <CardIcon Icon={MessageIcon} bgColor="#00a65a"/>
        <Card className={classes.card}>
            <Typography className={classes.title} component="h2" variant="headline">
                {translate('app.dashboard.contact.title')}
            </Typography>
            {value.nbTotalContact &&
            <>
                <Typography color="textSecondary">
                    {value.nbTotalContact} {translate('app.dashboard.contact.total')}
                </Typography>
                {value.nbPendingContact > 0 ?
                    <Typography color="error" style={styles.alertBold}>
                        {value.nbPendingContact} {translate('app.dashboard.contact.pending')}
                    </Typography>
                    :
                    <Typography color="textSecondary">
                        {value.nbPendingContact} {translate('app.dashboard.contact.pending')}
                    </Typography>
                }

            </>
            }
            {value.nbTotalContact === undefined && <CircularProgress
                variant="indeterminate"
                size={24}
                thickness={4}
            />}
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(DashboardCard);
