
/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addCustomFiltering = requestHandler => async (type, resource, params) => {

    if ('GET_LIST' === type && 'achat_form_results' === resource  ) {
        if (typeof params.filter.concession !== 'undefined') {
            params.filter['annonce.concession'] = params.filter.concession;
        }
        if (typeof params.filter.modele !== 'undefined') {
            params.filter['annonce.modele'] = params.filter.modele;
        }
    }

    return requestHandler(type, resource, {
        ...params,
        data: {
            ...params.data,
        },
    });
};

export default addCustomFiltering;
