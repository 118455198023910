import React from 'react';
import {Edit, required, SimpleForm, TextInput, translate} from 'react-admin';

const validateField = required();

const EnergyTitle = translate(({record, translate: t}) => {
    return <span>{t('app.energies.edit.title', {name: record.name})}</span>;
});

const EnergyEdit = props => (
    <Edit title={<EnergyTitle/>} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField}/>
        </SimpleForm>
    </Edit>
);

export default EnergyEdit;
