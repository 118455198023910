import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudUpdate, SaveButton } from 'react-admin';
import PropTypes from 'prop-types';

const setStatus = (crudType, status, values, record, basePath, redirectTo) => {
    if ('update' === crudType) {
        return crudUpdate('modeles', values.id, { ...values, status }, record, basePath, redirectTo);
    }
};

class SaveAndPublishModelButtonView extends Component {
    handleClick = () => {
        const { crudType, status, record, basePath, handleSubmit, redirect, setStatus } = this.props;

        return handleSubmit(values => setStatus(crudType, status, values, record, basePath, redirect));
    };

    render() {
        const { handleSubmitWithRedirect, setStatus, crudType, status, ...props } = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                {...props}
            />
        );
    }
}

SaveAndPublishModelButtonView.propTypes = {
    status: PropTypes.bool.isRequired,
    crudType: PropTypes.oneOf(['update']).isRequired,
};

const SaveAndPublishModelButton = connect(
    undefined,
    { setStatus }
)(SaveAndPublishModelButtonView);

export default SaveAndPublishModelButton;
