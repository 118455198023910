import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import {
    AirportShuttle,
    DirectionsBus,
    DirectionsCar,
    FileUpload,
    Hotel,
    LabelOutline,
    LocalGasStation,
    Mail,
    NaturePeople,
    NotificationsActive,
    Person,
    RvHookup,
    Settings,
    Store
} from '@material-ui/icons';
import { DashboardMenuItem, MenuItemLink, translate, WithPermissions } from 'react-admin';
import SubMenu from './CustomElements/SubMenu';
import Utils from '../lib/Utils';

class Menu extends Component {
    state = {
        menuAds: true,
        menuContacts: true,
        menuUsers: false,
        menuParameters: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }))
    };

    render() {
        const { onMenuClick, open, translate, permissions } = this.props;

        if (Utils.isAdmin(permissions)) {
            return (
                <>
                    {' '}
                    <DashboardMenuItem onClick={onMenuClick} />
                    <SubMenu
                        handleToggle={() => this.handleToggle('menuAds')}
                        isOpen={this.state.menuAds}
                        sidebarIsOpen={open}
                        name="resources.annonces.name"
                        icon={<AirportShuttle />}
                    >
                        <MenuItemLink
                            to={'/modeles'}
                            primaryText={translate('resources.modeles.name')}
                            leftIcon={<DirectionsBus />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/annonces'}
                            primaryText={translate('resources.annonces.name')}
                            leftIcon={<AirportShuttle />}
                            onClick={onMenuClick}
                        />
                    </SubMenu>
                    <SubMenu
                        handleToggle={() => this.handleToggle('menuContacts')}
                        isOpen={this.state.menuContacts}
                        sidebarIsOpen={open}
                        name="resources.contact_form_results.name_category"
                        icon={<NotificationsActive />}
                    >
                        <MenuItemLink
                            to={'/contact_form_results'}
                            primaryText={translate('resources.contact_form_results.name')}
                            leftIcon={<Mail />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/achat_form_results'}
                            primaryText={translate('resources.achat_form_results.name')}
                            leftIcon={<NotificationsActive />}
                            onClick={onMenuClick}
                        />

                    </SubMenu>
                    <SubMenu
                        handleToggle={() => this.handleToggle('menuUsers')}
                        isOpen={this.state.menuUsers}
                        sidebarIsOpen={open}
                        name="resources.concessions.name_category"
                        icon={<Store />}
                    >
                        <MenuItemLink
                            to={'/concessions'}
                            primaryText={translate('resources.concessions.name')}
                            leftIcon={<Store />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/users'}
                            primaryText={translate('resources.users.name')}
                            leftIcon={<Person />}
                            onClick={onMenuClick}
                        />
                    </SubMenu>
                    <SubMenu
                        handleToggle={() => this.handleToggle('menuParameters')}
                        isOpen={this.state.menuParameters}
                        sidebarIsOpen={open}
                        name="resources.options.name_category"
                        icon={<Settings />}
                    >
                        <MenuItemLink
                            to="/imports"
                            primaryText={translate('app.imports.list.title')}
                            leftIcon={<FileUpload />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/amenagements'}
                            primaryText={translate('resources.amenagements.name')}
                            leftIcon={<Hotel />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/boite_vitesses'}
                            primaryText={translate('resources.boite_vitesses.name')}
                            leftIcon={<DirectionsCar />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/energies'}
                            primaryText={translate('resources.energies.name')}
                            leftIcon={<LocalGasStation />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/finitions'}
                            primaryText={translate('resources.finitions.name')}
                            leftIcon={<AirportShuttle />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/marques'}
                            primaryText={translate('resources.marques.name')}
                            leftIcon={<LabelOutline />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/options'}
                            primaryText={translate('resources.options.name')}
                            leftIcon={<Settings />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/option_categories'}
                            primaryText={translate('resources.option_categories.name')}
                            leftIcon={<Settings />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/porteurs'}
                            primaryText={translate('resources.porteurs.name')}
                            leftIcon={<RvHookup />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/usages'}
                            primaryText={translate('resources.usages.name')}
                            leftIcon={<NaturePeople />}
                            onClick={onMenuClick}
                        />
                    </SubMenu>
                </>
            )
        } else {
            return (
                <>
                    {' '}
                    <DashboardMenuItem onClick={onMenuClick} />
                    <MenuItemLink
                        to={'/annonces'}
                        primaryText={translate('resources.annonces.name')}
                        leftIcon={<AirportShuttle />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={'/achat_form_results'}
                        primaryText={translate('resources.achat_form_results.name')}
                        leftIcon={<NotificationsActive />}
                        onClick={onMenuClick}
                    />
                </>
            )
        }

    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

const MenuPermissions = ({ location, match, ...rest }) => (
    <WithPermissions
        render={({ permissions }) => (
            <Menu permissions={permissions} location={location} match={match} {...rest} />
        )}
    />
);

export default enhance(MenuPermissions);
