module.exports = {
    fr: {
        "Expired JWT Token": "Session expiré",
        "Internal Server Error": "Erreur interne du serveur",
        "Invalid JWT Token": "Session invalide",
        "Bad credentials": "Identifiants invalides.",
        "Access Denied.": "Accès non autorisé.",
        app: {
            achat_contact: {
                edit: {
                    title: "Demande de contact de \"%{email}\""
                },
                fields: {
                    concession: "Concession",
                    modele: "Modèle",
                },
            },
            ads: {
                actions: {
                    create_new_ad: "Créer à partir de ce modèle",
                    ask_new_model: "Demander la création d'un nouveau modèle",
                    save_and_draft: "Enregistrer (brouillon)",
                    save_and_publish: "Enregistrer et publier",
                    save_and_unpublish: "Dé-publier",
                    save_and_archive: "Archiver",
                    save_and_re_publish: "Enregistrer et Soumettre à nouveau",
                },
                edit: {
                    title: "Annonce \"%{reference}\""
                },
                moderation: {
                    title: "Modération",
                    status: "Statut : %{status}",
                    moderationText: "Motif de la modération",
                    moderationTextHelp: "(requis si refus)",
                    btnApprove: "Accepter",
                    btnDecline: "Refuser",
                    approve: "Annonce acceptée",
                    decline: "Annonce refusée",
                    error: "Erreur lors de la modération de l'annonce",
                    publishDate: "Date de publication",
                    unpublishDate: "Date de fin de publication",
                    annee: "Année du modèle",
                    notDisplayed: "Ne sera pas publiée",
                },
                status: {
                    draft: "Brouillon",
                    published: "Publiée",
                    unpublished: "Dé-publiée",
                    refused: "Refusée",
                    archived: "Archivée",
                    pending: "En attente",
                },
                tabs: {
                    model: "Modèle",
                    publication: "Publication",
                    weight: "Poids",
                    informations: "Informations",
                    ad: "Annonce",
                    options: "Options",
                    media: "Média"
                },
                errors: {
                    fileTooBig: "La limite de taille de 2Mo est dépassée."
                }
            },
            amenagements: {
                edit: {
                    title: "Aménagement \"%{name}\""
                }
            },
            brands: {
                edit: {
                    title: "Marque \"%{name}\""
                }
            },
            carriers: {
                edit: {
                    title: "Porteur \"%{name}\""
                }
            },
            concessions: {
                edit: {
                    title: "Concession \"%{name}\""
                }
            },
            contact: {
                edit: {
                    title: "Demande de contact de \"%{email}\""
                }
            },
            dashboard: {
                ads: {
                    title: "Annonces",
                    total: "annonce(s) au total",
                    draft: "annonce(s) en attente",
                    published: "annonce(s) publiées"
                },
                contact: {
                    title: "Contact véhicules",
                    total: "message(s) au total",
                    pending: "message(s) à traiter"
                },
                concession: {
                    title: "Concessions et utilisateurs",
                    concessions : "concessions",
                    users : "utilisateurs",
                },
                user: {
                    title: "Utilisateurs"
                },
                marques: {
                    title: "Sites des différentes marques"
                },
                models: {
                    title: "Modèles",
                    pending: "modèle(s) sont en attente de validation",
                    total: "modèle(s) au total"
                },
            },
            energies: {
                edit: {
                    title: "Énergie \"%{name}\""
                }
            },
            finitions: {
                edit: {
                    title: "Finition \"%{name}\"",
                },
            },
            gears: {
                edit: {
                    title: "Boîte de vitesse \"%{name}\""
                }
            },
            global: {
                errors: {
                    fieldRequired: "Ce champ est requis",
                    invalidDate: "Cette date n'est pas valide"
                },
                yes: 'Oui',
                no: 'Non',
                submit: "Envoyer",
                mark_as_done: "Marquer résolus",
            },
            imports: {
                list: {
                    title: 'Imports',
                },
                btn: {
                    users: 'Importer des utilisateurs',
                    concessions: 'Importer des concessions',
                    options: 'Importer des options',
                    models: 'Importer des modèles',
                },
                common: {
                    format_to_respect: 'Format de fichier à respecter :',
                    table: {
                        column: 'Colonne',
                        description: 'Description',
                        required: 'Requis ?',
                        specificity: 'Spécificités',
                    },
                },
                users: {
                    title: 'Import des utilisateurs',
                },
                concessions: {
                    title: 'Import des concessions',
                },
                options: {
                    title: 'Import des options',
                },
                models: {
                    title: 'Import des modèles',
                },
            },
            login: {
                site: {
                    label: "Site",
                    AVIS_FRANCE: "PILOTE OCCASIONS FR",
                    AVIS_ENGLAND: "Autre"
                }
            },
            media: {
                label: "Media",
                image: "Image",
            },
            models: {
                actions : {
                    save_and_publish: "Enregistrer et publier",
                    save : "Enregistrer sans publier",
                },
                tabs: {
                    information: "Informations",
                    weight: "Poids",
                    dimensions: "Dimensions",
                    media: "Media"
                },
                edit: {
                    title: "Modèle \"%{name}\""
                },
                askNew: {
                    title: "Demander la création d'un nouveau modèle",
                    p1: "Si le modèle que vous cherchez n'est pas présent, veuillez remplir le formulaire ci-dessous. Un e-mail sera envoyé à nos équipes afin qu'elles ajoutent le modèle en question.",
                    mailSent: "Demande de création envoyée.",
                    mailNotSent: "Erreur lors de l'envoi de la demande de création.",
                    alreadyExist : {
                        status : {
                            true: 'Le modèle demandé existe déjà',
                            false : 'Le modèle demandé est déjà en cours de création et sera bientôt disponible',
                        },
                    },
                    form: {
                        label: {
                            brand: "Marque",
                            profil: "Profil",
                            reference: "Modèle demandé",
                            nbPlaceCarteGrise: "Nombres de place Carte grise",
                            nbPlaceCouchage: "Nombres de place de couchage",
                            nbPlaceRepas: "Nombres de place de repas",
                            amenagement: "Aménagement",
                            usage: "Usage",
                            chargeUtile: "Charge utile",
                            masseMaxi: "Masse maximum",
                            longueur: "Longueur",
                            largeur: "Largeur",
                            hauteur: "Hauteur",
                        },
                    },
                },
                status : {
                    true : 'Publié',
                    false: 'En attente'
                },
            },
            options: {
                edit: {
                    title: "Option \"%{name}\""
                }
            },
            profiles: {
                edit: {
                    title: "Profil \"%{name}\""
                }
            },
            roles: {
                label: "Roles"
            },
            usages: {
                edit: {
                    title: "Usage \"%{name}\""
                }
            },
            user: {
                edit: {
                    title: "Utilisateur \"%{email}\""
                },
                create: {
                    title: "Créer un nouvel utilisateur"
                }
            },
        },
        resources: {
            achat_form_results: {
                name: "Contact véhicule",
                fields: {
                    email: "E-mail",
                    civilite: "Civilité",
                    prenom: "Prénom",
                    nom: "Nom",
                    telephone: "N° de téléphone",
                    codePostal: "Code postal",
                    createdAt: "Date de la demande",
                    message: "Message",
                    souhaiteEssai: "Demande d'essai",
                    traite: "Traité",
                },
            },
            amenagements: {
                name: "Aménagements",
            },
            annonces: {
                name: "Annonces",
                fields: {
                    profil: "Profil",
                    modele: "Modèle",
                    marque: "Marque",
                    reference: "Référence",
                    price: "Prix",
                    kilometrage: "Kilométrage",
                    publishDate: "Date de publication",
                    unpublishDate: "Date de dépublication",
                    chargeUtile: "Charge utile",
                    masseMaximum: "Masse maximum",
                    usage: "Usage",
                    porteur: "Porteur",
                    nbPlaceCarteGrise: "Nombres de place Carte grise",
                    nbPlaceCouchage: "Nombres de place de couchage",
                    nbPlaceRepas: "Nombres de place de repas",
                    annee: "Année",
                    showSimulator: "Afficher le simulateur",
                    chevauxDin: "Chevaux DIN",
                    chevauxFiscaux: "Chevaux fiscaux",
                    boiteVitesse: "Boîte de vitesses",
                    energie: "Énergie",
                    concession: "Concession",
                    garantie: "Garantie",
                    description: "Description de l'annonce",
                    visite360: "Visite 360",
                    options: "Options",
                    amenagements: "Aménagements",
                    annonceImage: "Images",
                    fichierCatalogue: "Fichier PDF",
                    status: "Statut",
                    internReference: "Ma référence interne",
                    internReferenceHelper: "Celle-ci ne sera pas publié"
                },
            },
            boite_vitesses: {
                name: "Boîte de vitesse",
                fields: {
                    name: "Nom"
                }
            },
            concessions: {
                name: "Concessions",
                name_category: "Concessions et utilisateurs",
                fields: {
                    nom: "Nom",
                    villeRattachement: "Ville de rattachement",
                    emailContact: "E-mail de contact",
                    phoneContactGeneral: "Téléphone général",
                    nameContactAnnonce: "Nom complet du contact annonce",
                    phoneContactAnnonce: "Téléphone annonce",
                    email: "Email",
                    adresse: {
                        address1: "Adresse",
                        address2: "Adresse (bis)",
                        postCode: "Code postal",
                        city: "Ville",
                    },
                    geoPosition: {
                        latitude: "Latitude",
                        longitude: "Longitude",
                    },
                    destinatairesFormAchat: {
                        to: "Destinataires du formulaire d'achat - À...",
                        cc: "Destinataires du formulaire d'achat - CC...",
                    },
                    useFunding: 'Utiliser le simulateur',
                },
            },
            contact_form_results: {
                name: "Contact",
                name_category: "Contacts",
                fields: {
                    email: "E-mail",
                    civilite: "Civilité",
                    prenom: "Prénom",
                    nom: "Nom",
                    telephone: "N° de téléphone",
                    codePostal: "Code postal",
                    createdAt: "Date de la demande",
                    subject: "Sujet",
                    message: "Message",
                    traite: "Traité",
                }
            },
            energies: {
                name: "Énergies",
                fields: {
                    name: "Nom"
                }
            },
            finitions: {
                name: "Finitions",
                fields: {
                    name: "Nom",
                    brand: "Marque",
                    profile: "Profil",
                },
            },
            marques: {
                name: "Marques",
                fields: {
                    name: "Nom"
                }
            },
            modeles: {
                name: "Modèles",
                fields: {
                    reference: "Référence",
                    name: "Nom du modèle",
                    amenagements: "Aménagements",
                    nbPlaceCarteGrise: "Nombres de place Carte grise",
                    nbPlaceCouchage: "Nombres de place de couchage",
                    nbPlaceRepas: "Nombres de place de repas",
                    annonceImage: "Images",
                    fichierCatalogue: "Fichier PDF",
                    status: "Statut"
                }
            },
            options: {
                edit: {
                    title: "Option \"%{name}\""
                },
                source: "Source",
                name: "Options",
                name_category: "Paramètres",
                fields: {
                    name: "Nom",
                    category: "Categorie"
                }
            },
            option_categories: {
                edit: {
                    title: "Categories d'Option \"%{name}\""
                },
                source: "Source",
                name: "Categories d'Option",
                name_category: "Paramètres",
                fields: {
                    name: "Nom",
                }
            },
            porteurs: {
                name: "Porteurs",
            },
            profiles: {
                name: "Profils",
                fields: {
                    name: "Nom"
                }
            },
            usages: {
                name: "Usages",
                fields: {
                    name: "Nom"
                }
            },
            users: {
                name: "Utilisateurs",
                fields: {
                    name: "Nom",
                    active: "Actif",
                }
            }
        },
        ra: {
            message: {
                about: 'Au sujet de',
                are_you_sure: 'Êtes-vous sûr ?',
                bulk_delete_content:
                    'Êtes-vous sûr(e) de vouloir supprimer cet élément ? |||| Êtes-vous sûr(e) de vouloir supprimer ces %{smart_count} éléments ?',
                bulk_delete_title:
                    'Supprimer %{name} |||| Supprimer %{smart_count} %{name}',
                delete_content:
                    'Êtes-vous sûr(e) de vouloir supprimer cet élément ?',
                delete_title: 'Supprimer %{name} ?',
                details: 'Détails',
                error:
                    "En raison d'une erreur côté navigateur, votre requête n'a pas pu aboutir.",
                invalid_form: "Le formulaire n'est pas valide.",
                loading:
                    'La page est en cours de chargement, merci de bien vouloir patienter.',
                no: 'Non',
                not_found:
                    "L'URL saisie est incorrecte, ou vous avez suivi un mauvais lien.",
                yes: 'Oui',
                page_rows_per_page: "Lignes par page"
            },
            action: {
                add_filter: 'Ajouter un filtre',
                add: 'Ajouter',
                back: 'Retour',
                bulk_actions:
                    '%{smart_count} selectionné |||| %{smart_count} selectionnés',
                cancel: 'Annuler',
                clear_input_value: 'Vider le champ',
                clone: 'Dupliquer',
                confirm: 'Confirmer',
                create: 'Créer',
                delete: 'Supprimer',
                edit: 'Éditer',
                export: 'Exporter',
                list: 'Liste',
                refresh: 'Actualiser',
                remove_filter: 'Supprimer ce filtre',
                remove: 'Supprimer',
                save: 'Enregistrer',
                search: 'Rechercher',
                show: 'Afficher',
                sort: 'Trier',
                undo: 'Annuler',
                expand: 'Étendre',
                close: 'Fermer',
            },
            navigation: {
                no_results: 'Aucun résultat',
                no_more_results:
                    'La page numéro %{page} est en dehors des limites. Essayez la page précédente.',
                page_out_of_boundaries: 'La page %{page} est en dehors des limites',
                page_out_from_end: 'Fin de la pagination',
                page_out_from_begin: 'La page doit être supérieure à 1',
                page_range_info: '%{offsetBegin}-%{offsetEnd} sur %{total}',
                page_rows_per_page: 'Lignes par page :',
                next: 'Suivant',
                prev: 'Précédent',
            },
            auth: {
                user_menu: 'Profil',
                username: 'Identifiant',
                password: 'Mot de passe',
                sign_in: 'Connexion',
                sign_in_error: "Échec de l'authentification, merci de réessayer",
                logout: 'Déconnexion',
            },
            notification: {
                updated:
                    'Élément mis à jour |||| %{smart_count} élements mis à jour',
                created: 'Élément créé',
                deleted: 'Élément supprimé |||| %{smart_count} élements supprimés',
                bad_item: 'Élément inconnu',
                item_doesnt_exist: "L'élément n'existe pas",
                http_error: 'Erreur de communication avec le serveur',
                data_provider_error:
                    'Erreur dans le dataProvider. Plus de détails dans la console.',
                canceled: 'Action annulée',
                logged_out: 'Votre session a pris fin, veuillez vous reconnecter.',
            },
            validation: {
                required: 'Ce champ est requis',
                minLength: 'Minimum %{min} caractères',
                maxLength: 'Maximum %{max} caractères',
                minValue: 'Minimum %{min}',
                maxValue: 'Maximum %{max}',
                number: 'Doit être un nombre',
                email: 'Doit être un email',
                oneOf: 'Doit être au choix: %{options}',
                regex: 'Doit respecter un format spécifique (regexp): %{pattern}',
            },
            page: {
                create: 'Créer %{name}',
                dashboard: 'Tableau de bord',
                edit: '%{name} #%{id}',
                error: 'Un problème est survenu',
                list: '%{name}',
                loading: 'Chargement',
                not_found: 'Page manquante',
                show: '%{name} #%{id}',
            },
            input: {
                file: {
                    upload_several:
                        'Déposez les fichiers à uploader, ou cliquez pour en sélectionner.',
                    upload_single:
                        'Déposez le fichier à uploader, ou cliquez pour le sélectionner.',
                },
                image: {
                    upload_several:
                        'Déposez les images à uploader, ou cliquez pour en sélectionner.',
                    upload_single:
                        "Déposez l'image à uploader, ou cliquez pour la sélectionner.",
                },
                references: {
                    all_missing: 'Impossible de trouver des données de références.',
                    many_missing:
                        'Au moins une des références associées semble ne plus être disponible.',
                    single_missing:
                        'La référence associée ne semble plus disponible.',
                },
            },
            boolean: {
                true: 'Oui',
                false: 'Non',
            },
        }
    }
};
