import React, { useEffect, useState } from 'react';
import {
    GET_LIST,
    showNotification,
    Title,
    translate,
    WithPermissions
} from 'react-admin';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Input } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import customDataProvider from '../../lib/hydra/hydraClient';
import resources from '../../lib/hydra/resources';
import Utils from '../../lib/Utils';
import Api from '../../lib/Api';
import CircularProgress from '@material-ui/core/CircularProgress';

const dataProvider = customDataProvider({ resources });

const loadList = (resource, callback) => {
    dataProvider(GET_LIST, resource, {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
        filter: {},
    })
        .then(({ data }) => {
            callback(data);
        });
};

const AskNew = ({ translate: t, permissions, showNotification, push }) => {
    const [brandsList, setBrandsList] = useState([]);
    const [profilsList, setProfilsList] = useState([]);
    const [amenagementsList, setAmenagementsList] = useState([]);
    const [usagesList, setUsagesList] = useState([]);
    const [sending, setSending] = useState(false);
    const brand = Utils.useFormInput('');
    const profil = Utils.useFormInput('');
    const amenagement = Utils.useFormInput('');
    const usage = Utils.useFormInput('');
    const reference = Utils.useFormInput('');
    const nbPlaceCarteGrise = Utils.useFormInput('');
    const nbPlaceCouchage = Utils.useFormInput('');
    const nbPlaceRepas = Utils.useFormInput('');
    const chargeUtile = Utils.useFormInput('');
    const masseMaxi = Utils.useFormInput('');
    const longueur = Utils.useFormInput('');
    const largeur = Utils.useFormInput('');
    const hauteur = Utils.useFormInput('');

    useEffect(() => {
        if (null !== permissions) {
            loadList('marques', setBrandsList);
            loadList('profiles', setProfilsList);
            loadList('amenagements', setAmenagementsList);
            loadList('usages', setUsagesList);
        }
    }, [permissions]);

    const handleSubmission = e => {
        e.preventDefault();

        setSending(true);

        Api.askNewModel(
            brand.value,
            profil.value,
            reference.value,
            nbPlaceCarteGrise.value,
            nbPlaceCouchage.value,
            nbPlaceRepas.value,
            amenagement.value,
            usage.value,
            chargeUtile.value,
            masseMaxi.value,
            longueur.value,
            largeur.value,
            hauteur.value
            )
            .then(result => {
                if (result && result.alreadyExist === true) {
                    if (result.status === true) {
                        showNotification('app.models.askNew.alreadyExist.status.true', 'error')
                    } else {
                        showNotification('app.models.askNew.alreadyExist.status.false', 'error')
                    }
                } else {
                    showNotification('app.models.askNew.mailSent');

                    push('/annonces/create');
                }
            })
            .catch(e => showNotification('app.models.askNew.mailNotSent', 'error'))
            .finally(() => setSending(false))
        ;
    };

    return (
        <Card>
            <Title title={t('app.models.askNew.title')} />
            <CardContent>
                <Typography variant="title">{t('app.models.askNew.title')}</Typography>
                <br />

                <Typography variant="body1">{t('app.models.askNew.p1')}</Typography>
                <br />

                <form onSubmit={handleSubmission}>
                    <Grid container spacing={24}>
                        <Grid item xs>
                            <FormControl fullWidth required>
                                <InputLabel htmlFor="brand">{t('app.models.askNew.form.label.brand')}</InputLabel>
                                <Select
                                    native
                                    required
                                    value={brand.value}
                                    onChange={brand.onChange}
                                    inputProps={{
                                        name: 'brand',
                                        id: 'brand',
                                    }}
                                >
                                    <option></option>
                                    {brandsList.map(item => (
                                        <option key={item.id} value={item.name}>{item.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth required>
                                <InputLabel htmlFor="profil">{t('app.models.askNew.form.label.profil')}</InputLabel>
                                <Select
                                    native
                                    required
                                    value={profil.value}
                                    onChange={profil.onChange}
                                    inputProps={{
                                        name: 'profil',
                                        id: 'profil',
                                    }}
                                >
                                    <option></option>
                                    {profilsList.map(item => (
                                        <option key={item.id} value={item.name}>{item.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth required>
                                <InputLabel htmlFor="reference">{t('app.models.askNew.form.label.reference')}</InputLabel>
                                <Input
                                    id="reference"
                                    value={reference.value}
                                    onChange={reference.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="nbPlaceCarteGrise">{t('app.models.askNew.form.label.nbPlaceCarteGrise')}</InputLabel>
                                <Input
                                    id="nbPlaceCarteGrise"
                                    value={nbPlaceCarteGrise.value}
                                    onChange={nbPlaceCarteGrise.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="nbPlaceCouchage">{t('app.models.askNew.form.label.nbPlaceCouchage')}</InputLabel>
                                <Input
                                    id="nbPlaceCouchage"
                                    value={nbPlaceCouchage.value}
                                    onChange={nbPlaceCouchage.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="nbPlaceRepas">{t('app.models.askNew.form.label.nbPlaceRepas')}</InputLabel>
                                <Input
                                    id="nbPlaceRepas"
                                    value={nbPlaceRepas.value}
                                    onChange={nbPlaceRepas.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="amenagement">{t('app.models.askNew.form.label.amenagement')}</InputLabel>
                                <Select
                                    native
                                    value={amenagement.value}
                                    onChange={amenagement.onChange}
                                    inputProps={{
                                        name: 'amenagements',
                                        id: 'amenagements',
                                    }}
                                >
                                    <option></option>
                                    {amenagementsList.map(item => (
                                        <option key={item.id} value={item.name}>{item.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="usage">{t('app.models.askNew.form.label.usage')}</InputLabel>
                                <Select
                                    native
                                    value={usage.value}
                                    onChange={usage.onChange}
                                    inputProps={{
                                        name: 'usage',
                                        id: 'usage',
                                    }}
                                >
                                    <option></option>
                                    {usagesList.map(item => (
                                        <option key={item.id} value={item.name}>{item.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>


                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="chargeUtile">{t('app.models.askNew.form.label.chargeUtile')}</InputLabel>
                                <Input
                                    id="chargeUtile"
                                    value={chargeUtile.value}
                                    onChange={chargeUtile.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="masseMaxi">{t('app.models.askNew.form.label.masseMaxi')}</InputLabel>
                                <Input
                                    id="masseMaxi"
                                    value={masseMaxi.value}
                                    onChange={masseMaxi.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="longueur">{t('app.models.askNew.form.label.longueur')}</InputLabel>
                                <Input
                                    id="longueur"
                                    value={longueur.value}
                                    onChange={longueur.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="largeur">{t('app.models.askNew.form.label.largeur')}</InputLabel>
                                <Input
                                    id="largeur"
                                    value={largeur.value}
                                    onChange={largeur.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="hauteur">{t('app.models.askNew.form.label.hauteur')}</InputLabel>
                                <Input
                                    id="hauteur"
                                    value={hauteur.value}
                                    onChange={hauteur.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={sending}
                            >
                                {sending &&
                                <CircularProgress
                                    variant="indeterminate"
                                    size={24}
                                    thickness={4}
                                    style={{ marginRight: 10 }}
                                />
                                }
                                {t('app.global.submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
};

const AskNewWithPermissions = props => (
    <WithPermissions render={({ permissions }) => <AskNew {...props} permissions={permissions} />} />
);

export default connect(null, {
    showNotification,
    push: pushAction
})(translate(AskNewWithPermissions));
