import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import {translate} from 'react-admin';
import CircularProgress from "@material-ui/core/CircularProgress";

const style = theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
    LinearProgress: {
        marginRight: '1em',
        marginLeft: '1em',
        color: theme.palette.primary,
    }
});

const BrandListCard = ({value = [], translate, classes}) => (
    <Card className={classes.root}>
        <CardHeader title={translate('app.dashboard.marques.title')}/>
        <List dense={true}>
            {value[0] &&
            <>
                {value.map(record => (
                    <ListItem
                        key={record.name}
                        button
                        onClick={() => window.open(record.url, '_blank')}
                    >
                        <ListItemText
                            primary={record.name.toUpperCase()}
                            secondary={record.url}
                        />
                    </ListItem>
                ))}
            </>}
            {!value[0] &&
            <>
                <ListItem key='#'>
                    <CircularProgress
                        variant="indeterminate"
                        size={24}
                        thickness={4}
                    />
                </ListItem>
            </>}
        </List>
    </Card>
);

const enhance = compose(
    withStyles(style),
    translate
);

export default enhance(BrandListCard);
