import React, { useState } from 'react';
import { translate, WithPermissions } from 'react-admin';
import { Button, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import Utils from '../../lib/Utils';
import { configuration, list } from './configurations';
import ResourceImport from './ResourceImport';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
    },
    button: {
        margin: theme.spacing.unit,
    },
});

const enhance = compose(
    withStyles(styles),
    translate
);

const ImportsList = enhance(({ permissions, classes, translate }) => {
    const [importType, setImportType] = useState(null);

    if (!Utils.isAdmin(permissions)) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography className={classes.title} component="h2" variant="headline">
                        {translate('app.imports.list.title')}
                    </Typography>
                </Grid>

                {list.map(({ label, type }) => (
                    <Grid item xs={3} key={label}>
                        <Paper className={classes.paper}>
                            <Button
                                variant="contained"
                                className={classes.button}
                                color="primary"
                                onClick={() => setImportType(type)}
                            >
                                {translate(label)}
                            </Button>
                        </Paper>
                    </Grid>
                ))}

                {importType &&
                <Grid item xs={12}>
                    <ResourceImport {...configuration[importType]} type={importType} />
                </Grid>
                }
            </Grid>
        </div>
    );
});

const ImportsListPermissions = ({ location, match: { path, params } }) => (
    <WithPermissions
        authParams={{ key: path, params }}
        location={location}
        render={({ permissions }) => <ImportsList permissions={permissions} />}
    />
);

export default ImportsListPermissions;
