import React, { useState } from 'react';
import omit from 'lodash/omit';

class Utils {
    /**
     * Format a record array containing only strings.
     *
     * @param value
     * @returns {string}
     */
    static formatRecordStringArrayInput = value => {
        return 'string' === typeof value ? value : "";
    };

    /**
     * Ensures a string array contains at least one non empty value.
     *
     * @param value
     * @param allValues
     * @param translate
     * @returns {Array|*}
     */
    static validateRequiredStringArray = (value, allValues, { translate }) => {
        if (
            'undefined' === typeof value ||
            0 !== value.filter(v => 'undefined' === typeof v).length ||
            0 === value.filter(v => v.length > 0).length
        ) {
            return translate('app.global.errors.fieldRequired');
        }
    };

    /**
     * Ensures the selected date is valid (date and time defined).
     *
     * @returns {function(*=, *=, *): {undefined|String}}
     */
    static validateDate = () => (value, allValues, props) => {
        return (!value || 'Invalid Date' !== value.toString()) ? undefined : props.translate('app.global.errors.invalidDate');
    };

    /**
     * Change new lines from a string to <br />.
     *
     * @param str
     * @returns {string|any[]|number}
     */
    static nl2br = str => {
        if ('number' === typeof str) {
            return str;
        } else if ('string' !== typeof str) {
            return '';
        }

        const newlineRegex = /(\r\n|\r|\n)/g;

        return str.split(newlineRegex).map((line, index) => {
            if (line.match(newlineRegex)) {
                return <br key={index} />;
            }

            return line;
        });
    };

    /**
     *
     * @param props
     */
    static sanitizeRestProps = props => {
        omit(props, [
            'addLabel',
            'allowEmpty',
            'basePath',
            'cellClassName',
            'className',
            'formClassName',
            'headerClassName',
            'label',
            'linkType',
            'locale',
            'record',
            'resource',
            'sortable',
            'sortBy',
            'source',
            'textAlign',
            'translateChoice',
        ]);
    };

    static checkboxGroupInputSanitizeProps = props => {
        omit(props, [
            'alwaysOn',
            'basePath',
            'component',
            'defaultValue',
            'formClassName',
            'initializeForm',
            'input',
            'isRequired',
            'label',
            'limitChoicesToValue',
            'locale',
            'meta',
            'options',
            'optionText',
            'optionValue',
            'record',
            'resource',
            'allowEmpty',
            'source',
            'textAlign',
            'translate',
            'translateChoice',
        ]);
    };


    /**
     * Use a form input. The input will have a value and a onChange callbak.
     *
     * @param initialValue
     * @returns {{onChange: handleChange, value: any}}
     */
    static useFormInput = initialValue => {
        const [value, setValue] = useState(initialValue);

        function handleChange(e) {
            setValue(e.target.value);
        }

        function reset() {
            setValue(initialValue);
        }

        return {
            value,
            reset,
            onChange: handleChange,
        };
    };

    /**
     * Gets the human readable name of a role.
     *
     * @param data
     * @returns {string}
     */
    static formatRoleName = data => {
        switch (data) {
            case 'ROLE_CONCESSIONNAIRE' :
                return 'Concessionnaire';

            case 'ROLE_SUPER_ADMIN':
            case 'ROLE_ADMIN' :
                return 'Admin';

            default:
                return 'Utilisateur'
        }
    };

    /**
     * Formats a string date to locale representation.
     *
     * @param dateStr
     * @returns {string}
     */
    static formatDate = dateStr => new Date(dateStr).toLocaleString();

    /**
     * Indicates if the user is an admin based on permissions.
     *
     * @param permissions
     * @returns {boolean}
     */
    static isAdmin = permissions => ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(permissions);

    /**
     * Indicates if the user is a super admin based on permissions.
     *
     * @param permissions
     * @returns {boolean}
     */
    static isSuperAdmin = permissions => 'ROLE_SUPER_ADMIN' === permissions;
}

export default Utils;
