import React from 'react';
import {Edit, required, SimpleForm, TextInput, translate, SelectInput, ReferenceInput} from 'react-admin';

const validateField = required();

const OptionTitle = translate(({record, translate: t}) => {
    return <span>{t('app.options.edit.title', {name: record.name})}</span>;
});

const OptionEdit = props => (
    <Edit title={<OptionTitle/>} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField}/>
            <ReferenceInput perPage={999} source="category" reference="option_categories">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default OptionEdit;
