import React from 'react';
import { Edit, ImageField, ImageInput, required, SimpleForm, TextInput, translate } from 'react-admin';

const validateField = required();

const AmenagementTitle = translate(({ record, translate: t }) => {
    return <span>{t('app.amenagements.edit.title', { name: record.name })}</span>;
});

const AmenagementEdit = props => (
    <Edit title={<AmenagementTitle />} undoable={false} {...props}>

        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField} />
            <ImageInput source="media" accept="image/*" validate={validateField}>
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export default AmenagementEdit;
