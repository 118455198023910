import React from 'react';
import {
    Create,
    FileField,
    FileInput,
    FormDataConsumer,
    FormTab,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    TabbedForm,
    TextInput
} from 'react-admin';

const validateField = required();

const ModelCreate = props => (
    <Create {...props}>
        <TabbedForm redirect="list">
            <FormTab label='app.models.tabs.information'>
                <TextInput source="reference" validate={validateField} label={"resources.modeles.fields.name"} />
                <ReferenceInput perPage={999} source="marque" reference="marques" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput perPage={999} source="profil" reference="profiles" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.marque && formData.profil &&
                        <ReferenceInput
                            perPage={999}
                            source="finition"
                            reference="finitions"
                            filter={{ brand: formData.marque, profile: formData.profil }}
                            sort={{ field: 'name', order: 'ASC' }}
                            {...rest}
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    }
                </FormDataConsumer>
                <NumberInput source="nbPlaceCarteGrise" validate={validateField} />
                <NumberInput source="nbPlaceCouchage" validate={validateField} />
                <NumberInput source="nbPlaceRepas" validate={validateField} />
                <ReferenceArrayInput perPage={999} source="amenagements" reference="amenagements" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                <ReferenceInput perPage={999} source="usage" reference="usages" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </FormTab>
            <FormTab label='app.models.tabs.weight'>
                <NumberInput source="chargeUtile" />
                <NumberInput source="masseMaxi" />
            </FormTab>
            <FormTab label='app.models.tabs.dimensions'>
                <NumberInput source="longueur" />
                <NumberInput source="largeur" />
                <NumberInput source="hauteur" />
            </FormTab>
            <FormTab label='app.models.tabs.media'>
                <ImageInput source="annonceImage" accept="image/*" multiple={true} validate={validateField}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <FileInput source="fichierCatalogue" accept="application/pdf">
                    <FileField source="src" title="title" />
                </FileInput>
                <TextInput source="urlVisite360" />
            </FormTab>
        </TabbedForm>
    </Create>
);

export default ModelCreate;
