import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { addField, FieldTitle, translate } from 'ra-core';

import Utils from '../lib/Utils';
import Api from '../lib/Api';

const sanitizeRestProps = ({ setFilter, setPagination, setSort, ...rest }) =>
    Utils.checkboxGroupInputSanitizeProps(rest);

const styles = theme =>
    createStyles({
        root: {},
        label: {
            transform: 'translate(0, 1.5px) scale(0.75)',
            transformOrigin: `top ${
                theme.direction === 'ltr' ? 'left' : 'right'
            }`,
        },
        checkbox: {
            height: 32,
        },
        row: {
            marginBottom: 10,
        },
    });

/**
 * An Input component for a checkbox group, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * The expected input must be an array of identifiers (e.g. [12, 31]) which correspond to
 * the 'optionValue' of 'choices' attribute objects.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *     { id: 12, name: 'Ray Hakt' },
 *     { id: 31, name: 'Ann Gullar' },
 *     { id: 42, name: 'Sean Phonee' },
 * ];
 * <OptionsCheckboxGroupInput source="recipients" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi' },
 *    { _id: 456, full_name: 'Jane Austen' },
 * ];
 * <OptionsCheckboxGroupInput source="recipients" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <OptionsCheckboxGroupInput source="recipients" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <OptionsCheckboxGroupInput source="recipients" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'programming', name: 'myroot.category.programming' },
 *    { id: 'lifestyle', name: 'myroot.category.lifestyle' },
 *    { id: 'photography', name: 'myroot.category.photography' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <OptionsCheckboxGroupInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <Checkbox> components
 */
class OptionsCheckboxGroupInput extends Component {
    optionsCategories = null;

    componentDidMount() {
        Api.getOptionCategories().then(result => {
            result.push({ url: 'undefined', name: 'Sans catégorie' });

            this.optionsCategories = result;
        });
    }

    handleCheck = (event, isChecked) => {
        const {
            input: { value, onChange },
        } = this.props;
        let newValue;
        try {
            // try to convert string value to number, e.g. '123'
            newValue = JSON.parse(event.target.value);
        } catch (e) {
            // impossible to convert value, e.g. 'abc'
            newValue = event.target.value;
        }
        if (isChecked) {
            onChange([...(value || []), ...[newValue]]);
        } else {
            onChange(value.filter(v => v != newValue)); // eslint-disable-line eqeqeq
        }
    };

    renderCheckbox = choice => {
        const {
            id,
            input: { value },
            optionText,
            optionValue,
            options,
            translate,
            translateChoice,
            classes,
        } = this.props;
        const choiceName = React.isValidElement(optionText)
            ? React.cloneElement(optionText, { record: choice })
            : typeof optionText === 'function'
                ? optionText(choice)
                : get(choice, optionText);
        return (
            <FormControlLabel
                htmlFor={`${id}_${get(choice, optionValue)}`}
                key={get(choice, optionValue)}
                checked={
                    value
                        ? value.find(v => v == get(choice, optionValue)) !== // eslint-disable-line eqeqeq
                        undefined
                        : false
                }
                onChange={this.handleCheck}
                value={String(get(choice, optionValue))}
                control={
                    <Checkbox
                        id={`${id}_${get(choice, optionValue)}`}
                        color="primary"
                        className={classes.checkbox}
                        {...options}
                    />
                }
                label={
                    translateChoice
                        ? translate(choiceName, { _: choiceName })
                        : choiceName
                }
            />
        );
    };

    renderOptions = (choices, classes, source, resource, isRequired) => {
        const dataset = [];
        const unknownCategory = this.optionsCategories ?
            this.optionsCategories.find(option => 'undefined' === option.url) :
            {};

        for (const choice of choices) {
            if (!dataset[choice.category]) {
                dataset[choice.category] = [];
            }

            dataset[choice.category].push(choice);
        }

        return Object.entries(dataset).map(([categoryUrl, categoryChoices], index) => {
            const category = this.optionsCategories ?
                this.optionsCategories.find(option => option.url === categoryUrl) :
                null
            ;

            return (
                <Fragment key={index}>
                    <FormLabel component="legend" className={classes.label}>
                        <FieldTitle
                            label={category ? category.name : unknownCategory.name}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    </FormLabel>
                    <FormGroup row className={classes.row}>{categoryChoices.map(choice => this.renderCheckbox(choice))}</FormGroup>
                </Fragment>
            );
        });
    };

    render() {
        const {
            choices,
            className,
            classes = {},
            isRequired,
            label,
            meta,
            resource,
            source,
            input,
            ...rest
        } = this.props;
        if (typeof meta === 'undefined') {
            throw new Error(
                "The OptionsCheckboxGroupInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
            );
        }

        const { touched, error, helperText = false } = meta;

        return (
            <FormControl
                className={className}
                component="fieldset"
                margin="normal"
                {...sanitizeRestProps(rest)}
            >
                {this.renderOptions(choices, classes, source, resource, isRequired)}
                {touched && error && (
                    <FormHelperText error>{error}</FormHelperText>
                )}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}

OptionsCheckboxGroupInput.propTypes = {
    choices: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    source: PropTypes.string,
    options: PropTypes.object,
    id: PropTypes.string,
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
    }),
    isRequired: PropTypes.bool,
    optionText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
    ]).isRequired,
    optionValue: PropTypes.string.isRequired,
    resource: PropTypes.string,
    translate: PropTypes.func.isRequired,
    translateChoice: PropTypes.bool.isRequired,
    meta: PropTypes.object,
};

OptionsCheckboxGroupInput.defaultProps = {
    choices: [],
    classes: {},
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
};

const EnhancedOptionsCheckboxGroupInput = compose(
    addField,
    translate,
    withStyles(styles)
)(OptionsCheckboxGroupInput);

EnhancedOptionsCheckboxGroupInput.defaultProps = {
    fullWidth: true,
};

export default EnhancedOptionsCheckboxGroupInput;
