import Parameters from "../../lib/Parameters";
import {amber, red, yellow } from "@material-ui/core/colors";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";

const availableApis = Parameters.getAvailableApis();
const currentSiteCode = localStorage.getItem('code_site');
let isCustom = false;

const apiData = {
    'AVIS_FRANCE': {
        'palette': {
            'primary': yellow,
            'secondary': '#ffffff',
            'error': red,
        },
        'label': '🇫🇷 Avis.fr',
    },
    'avis_england': {
        'palette': {
            'type': 'main',
            'primary': amber,
            'secondary': amber,
            'error': red,
        },
        'label': '🇬🇧 Avis.co.uk',

    }
};

for (let key in availableApis) {
    if (key === currentSiteCode && apiData.hasOwnProperty(key)) {
        isCustom = true;
    }
}
const theme = isCustom ? createMuiTheme({
    palette: createPalette({
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#ffffff',
        },
        error: apiData[currentSiteCode].palette.error,
    }),
}) : {};

export default theme;
