import React from 'react';
import { BooleanField, BulkDeleteButton, Datagrid, DateField, EditButton, List, TextField } from 'react-admin';
import CustomPagination from '../../Theming/CustomElements/CustomPagination';
import BulkDoneButton from '../../components/BulkDoneButton';

const BulkActionButtons = props => (
    <>
        <BulkDoneButton {...props} />
        <BulkDeleteButton {...props} />
    </>
);

const ContactFormResultList = props => (
    <List exporter={false} perPage={25} pagination={<CustomPagination />}
          bulkActionButtons={<BulkActionButtons />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="nom" sortable={false} />
            <TextField source="prenom" sortable={false} />
            <TextField source="email" sortable={false} />
            <DateField source="createdAt" sortable={false} />
            <BooleanField source="traite" sortable={false} />
            <EditButton />
        </Datagrid>
    </List>
);

export default ContactFormResultList;
