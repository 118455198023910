import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, crudUpdateMany, translate } from 'react-admin';
import DoneIcon from '@material-ui/icons/Done';

class BulkDoneButton extends Component {
    handleClick = () => {
        const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
        crudUpdateMany(resource, selectedIds, { traite: true }, basePath);
    };

    render() {
        const { translate } = this.props;

        return (
            <Button color="primary" label={translate('app.global.mark_as_done')} onClick={this.handleClick}>
                <DoneIcon />
            </Button>
        );
    }
}

export default connect(undefined, { crudUpdateMany })(translate(BulkDoneButton));
