import React from 'react';
import {Datagrid, EditButton, List, TextField} from 'react-admin';
import CustomPagination from "../../Theming/CustomElements/CustomPagination";

const EnergyList = props => (
    <List exporter={false} perPage={25} pagination={<CustomPagination />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export default EnergyList;
