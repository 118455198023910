import React from 'react';
import { BooleanInput, DateField, Edit, EmailField, SimpleForm, TextField, translate } from 'react-admin';

const ContactFormResultTitle = translate(({ record, translate: t }) => {
    return <span>{t('app.contact.edit.title', { email: record.email })}</span>;
});

const ContactFormResultEdit = props => (

    <Edit title={<ContactFormResultTitle />} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <EmailField source="email" />
            <TextField source="civilite" />
            <TextField source="prenom" />
            <TextField source="nom" />
            <TextField source="codePostal" />
            <TextField source="telephone" />
            <TextField source="codePostal" />
            <DateField source="createdAt" />
            <TextField source="subject" />
            <TextField source="message" />
            <BooleanInput source="traite" />
        </SimpleForm>
    </Edit>
);

export default ContactFormResultEdit;
