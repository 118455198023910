import React from 'react';
import {Edit, required, SimpleForm, TextInput, translate} from 'react-admin';

const validateField = required();

const OptionCategoryTitle = translate(({record, translate: t}) => {
    return <span>{t('app.options_category.edit.title', {name: record.name})}</span>;
});

const OptionCategoryEdit = props => (
    <Edit title={<OptionCategoryTitle/>} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField}/>
        </SimpleForm>
    </Edit>
);

export default OptionCategoryEdit;
