import React from 'react';
import {Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput} from 'react-admin';

const validateField = required();

const OptionCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField}/>
            <ReferenceInput perPage={999} source="category" reference="option_categories">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default OptionCreate;
