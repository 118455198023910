import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudCreate, crudUpdate, SaveButton } from 'react-admin';
import PropTypes from 'prop-types';

const setStatus = (crudType, status, values, record, basePath, redirectTo) => {
    if ('create' === crudType) {
        return crudCreate('annonces', { ...values, status }, basePath, redirectTo);
    } else if ('update' === crudType) {
        return crudUpdate('annonces', values.id, { ...values, status }, record, basePath, redirectTo);
    }
};

class SaveAndSetStatusButtonView extends Component {
    handleClick = () => {
        const { crudType, status, record, basePath, handleSubmit, redirect, setStatus } = this.props;

        return handleSubmit(values => setStatus(crudType, status, values, record, basePath, redirect));
    };

    render() {
        const { handleSubmitWithRedirect, setStatus, crudType, status, ...props } = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                {...props}
            />
        );
    }
}

SaveAndSetStatusButtonView.propTypes = {
    status: PropTypes.oneOf(['draft', 'pending', 'archived', 'unpublished']).isRequired,
    crudType: PropTypes.oneOf(['create', 'update']).isRequired,
};

const SaveAndSetStatusButton = connect(
    undefined,
    { setStatus }
)(SaveAndSetStatusButtonView);

export default SaveAndSetStatusButton;
