import React from 'react';
import {Edit, required, SimpleForm, TextInput, translate} from 'react-admin';

const validateField = required();

const UsageTitle = translate(({record, translate: t}) => {
    return <span>{t('app.usages.edit.title', {name: record.name})}</span>;
});

const UsageEdit = props => (
    <Edit title={<UsageTitle/>} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField}/>
        </SimpleForm>
    </Edit>
);

export default UsageEdit;
