import config from '../config';

class Parameters {
    #siteCodes;
    #envConfig;

    constructor() {
        this.#envConfig = config[window.location.hostname] || config['default'];
        this.#siteCodes = this.#envConfig.siteCodes;
    }

    /**
     * Gets available APIs.
     *
     * @returns {*}
     */
    getAvailableApis = () => {
        const apis = {};

        for (const siteCode of this.#siteCodes) {
            apis[siteCode] = this.#envConfig.apiBaseUri[siteCode];
        }

        return apis;
    };

    /**
     * Gets the URL of the hydra documentation to parse.
     *
     * @returns {string}
     */
    getHydraDocumentationUrl = () => {
        return this.#envConfig.apiBaseUri.AVIS_FRANCE;
    };
}

export default new Parameters();
