import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

const ImportSummary = ({ creation, update, errors }) => (
    <>
        <p>Résumé de l'import</p>

        <ul>
            <li>Nombre de création : <strong>{creation}</strong></li>
            {undefined !== update && <li>Nombre de mise à jour : <strong>{update}</strong></li>}
        </ul>

        {errors && !!errors.length &&
        <>
            <Typography color="error" component="p">Liste des erreurs</Typography>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Ligne</TableCell>
                        <TableCell>Messages</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {errors.map((error, i) => {
                        if ('string' === typeof error) {
                            return (
                                <TableRow key={i}>
                                    <TableCell colSpan={2}>{error}</TableCell>
                                </TableRow>
                            )
                        }

                        const { line, messages } = error;

                        return (
                            <TableRow key={i}>
                                <TableCell>{line}</TableCell>
                                <TableCell>{messages.map((msg, i) => <p key={i}>{msg}</p>)}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
        }
    </>
);

ImportSummary.propTypes = {
    creation: PropTypes.number.isRequired,
    update: PropTypes.number,
    errors: PropTypes.array,
};

export default ImportSummary;
