import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';
import Api from './Api';
import jwt_decode from 'jwt-decode';

export default (type, params) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
        return Api.login(params).then(({ token }) => {
            const decoded = jwt_decode(token);

            localStorage.setItem('token', token);
            localStorage.setItem('code_site', decoded.code_site.toUpperCase());
            localStorage.setItem('role', decoded.roles[0]);

        });
    }

    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        localStorage.removeItem('code_site');
        localStorage.removeItem('role');

        return Promise.resolve();
    }

    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const { response: { status } } = params;

        if (status === 401 || status === 403) {
            if (process.env.NODE_ENV === 'development') {
                console.error(`Erreur d'API, code ${status} renvoyé.`);
            } else {
                localStorage.removeItem('token');

                return Promise.reject();
            }
        }

        return Promise.resolve();
    }

    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }

    // called when we need to check user permissions
    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('role');

        return role ? Promise.resolve(role) : Promise.reject();
    }

    return Promise.reject('Unknown method');
};
