import React from 'react';
import {Edit, required, SimpleForm, TextInput, translate} from 'react-admin';

const validateField = required();

const BrandTitle = translate(({record, translate: t}) => {
    return <span>{t('app.brands.edit.title', {name: record.name})}</span>;
});

const BrandEdit = props => (
    <Edit title={<BrandTitle/>} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField}/>
        </SimpleForm>
    </Edit>
);

export default BrandEdit;
