import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {withStyles} from '@material-ui/core/styles';
import {Typography } from '@material-ui/core';
import {translate} from 'react-admin';

import CardIcon from './CardIcon';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
        cursor: 'pointer'
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    alertBold: {
        fontWeight: '600',
    }
};

const DashboardCard = ({value, translate, classes}) => (
    <div className={classes.main} onClick={() => window.location.hash = "#/annonces"}>
        <CardIcon Icon={AssignmentIcon} bgColor="#0073b7"/>
        <Card className={classes.card}>
            <Typography className={classes.title} component="h2" variant="headline">
                {translate('app.dashboard.ads.title')}
            </Typography>
            {value.nbTotalAnnonces &&
            <>
                <Typography color="textSecondary">
                    {value.nbTotalAnnonces} {translate('app.dashboard.ads.total')}
                </Typography>
                <Typography color="textSecondary">
                    {value.nbPublishedAnnonces} {translate('app.dashboard.ads.published')}
                </Typography>
                {value.nbDraftAnnonces > 0 ?
                    <Typography color="error"  style={styles.alertBold}>
                        {value.nbDraftAnnonces} {translate('app.dashboard.ads.draft')}
                    </Typography>
                    :
                    <Typography color="textSecondary">
                        {value.nbDraftAnnonces} {translate('app.dashboard.ads.draft')}
                    </Typography>
                }
            </>}
            {value.nbTotalAnnonces === undefined && <CircularProgress
                variant="indeterminate"
                size={24}
                thickness={4}
            />}
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(DashboardCard);
