import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import HomeIcon from '@material-ui/icons/Home';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {translate} from 'react-admin';
import CardIcon from './CardIcon';
import CircularProgress from "@material-ui/core/CircularProgress";


const styles = {
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
        cursor: 'pointer'
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
};

const DashboardCard = ({value, translate, classes}) => (
    <div className={classes.main} onClick={() => window.location.hash = "#/concessions"}>
        <CardIcon Icon={HomeIcon} bgColor="#39cccc"/>
        <Card className={classes.card}>
            <Typography className={classes.title} component="h2" variant="headline">
                {translate('app.dashboard.concession.title')}
            </Typography>
            {value.nbConcessions &&
                <>
                    <Typography color="textSecondary">
                        {value.nbConcessions} {translate('app.dashboard.concession.concessions')}
                    </Typography>

                    <Typography color="textSecondary">
                        {value.nbUsers} {translate('app.dashboard.concession.users')}
                    </Typography>
                </>
            }
            {!value.nbConcessions && <CircularProgress
                variant="indeterminate"
                size={24}
                thickness={4}
            />}
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(DashboardCard);
