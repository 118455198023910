import React from 'react';
import { Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';

const validateField = required();

const FinitionCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField} />
            <ReferenceInput perPage={999} source="brand" reference="marques" validate={validateField}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput perPage={999} source="profile" reference="profiles" validate={validateField}>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default FinitionCreate;
