import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Api from '../../lib/Api';
import jwt_decode from "jwt-decode";

const styles = theme =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,
            display: 'flex',
            alignItems: 'flex-start',
        },
        active: {
            color: theme.palette.text.primary,
        },
        icon: { paddingRight: '1.2em' },
    });

export class MenuItemSwitchAdmin extends Component {
    static propTypes = {
        primaryText: PropTypes.node,
        code_site: PropTypes.node.isRequired,
    };

    state = {
        auth: true,
        anchorEl: null,
    };

    handleMenuTap = e => {
        let code_site = this.props.code_site;
        this.props.onClick && this.props.onClick(e);

        Api.switchAdmin(code_site).then(({token}) => {

            if (token !== localStorage.getItem('token')) {
                const decoded = jwt_decode(token);
                localStorage.setItem('token', token);
                localStorage.setItem('code_site', decoded.code_site.toUpperCase());
                localStorage.setItem('role', decoded.roles[0]);

                window.location = '/';
            }


        });
    };

    render() {
        const {
            primaryText,
            staticContext,
            ...props
        } = this.props;

        return (
            <MenuItem
                {...props}
                onClick={this.handleMenuTap}
            >
                {primaryText}
            </MenuItem>
        );
    }
}

export default withStyles(styles)(MenuItemSwitchAdmin);
