import React from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    BooleanInput,
    SelectInput,
    translate,
    ReferenceArrayInput,
    SelectArrayInput,
    FormDataConsumer
} from 'react-admin';

const validateField = required();

const choices = [
    {id: 'ROLE_ADMIN', name: 'Admin'},
    {id: 'ROLE_CONCESSIONNAIRE', name: 'Concessionnaire'},
];

const UsageTitle = translate(({record, translate: t}) => {
    return <span>{t('app.user.create.title', {email: record.email})}</span>;
});

const roleParser = v => {
    return v ? [v] : [];
};

const roleFormat = v => {
    return v ? v[0] : '';
};

const UsageCreate = props => (
    <Create title={<UsageTitle/>} {...props}>
        <SimpleForm redirect="list">
            <BooleanInput source="active" options={{
                checked: true,
            }}/>
            <TextInput source="email" validate={validateField}/>
            <SelectInput source="roles" choices={choices} parse={roleParser} format={roleFormat}/>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.roles && formData.roles.includes('ROLE_CONCESSIONNAIRE') ? (
                    <ReferenceArrayInput source="concessions" label="Concessions" reference="concessions" perPage={999} {...rest}>
                        <SelectArrayInput optionText="nom"/>
                    </ReferenceArrayInput>
                ) : null}
            </FormDataConsumer>
            <TextInput type="password" label="Password" source="plainPassword"/>
        </SimpleForm>
    </Create>
);

export default UsageCreate;
