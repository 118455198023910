import React from 'react';
import {
    Edit,
    required,
    SimpleForm,
    TextInput,
    translate,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    FormDataConsumer,
    BooleanInput
} from 'react-admin';
import Utils from "../../lib/Utils";

const validateField = required();

const UsageTitle = translate(({record, translate: t}) => {
    return <span>{t('app.user.edit.title', {email: record.email})}</span>;
});

const choices = [
    {id: 'ROLE_ADMIN', name: Utils.formatRoleName('ROLE_ADMIN')},
    {id: 'ROLE_CONCESSIONNAIRE', name: Utils.formatRoleName('ROLE_CONCESSIONNAIRE')},
];

const roleParser = v => {
    return v ? [v] : [];
};

const roleFormat = v => {
    return v ? v[0] : '';
};


const UsageEdit = props => (
    <Edit title={<UsageTitle/>} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <BooleanInput source="active"/>
            <TextInput source="email" validate={validateField}/>
            <SelectInput source="roles" choices={choices} parse={roleParser} format={roleFormat}/>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.roles && formData.roles.includes('ROLE_CONCESSIONNAIRE') ? (
                    <ReferenceArrayInput source="concessions" label="Concessions" reference="concessions" perPage={999} {...rest}>
                        <SelectArrayInput optionText="nom"/>
                    </ReferenceArrayInput>
                ) : null}
            </FormDataConsumer>
            <TextInput type="password" label="Password" source="plainPassword"/>
        </SimpleForm>
    </Edit>
);

export default UsageEdit;
