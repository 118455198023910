import React, {Children, cloneElement} from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/index';
import IconButton from '@material-ui/core/IconButton/index';
import Menu from '@material-ui/core/Menu/index';
import {translate} from 'ra-core';
import CloudIcon from '@material-ui/icons/Cloud'
import customMessages from '../../i18n';

class AppBarMenu extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        label: PropTypes.string,
        logout: PropTypes.node,
        icon: PropTypes.node,
        translate: PropTypes.func.isRequired,
    };

    static defaultProps = {
        label: customMessages.fr.app.options.source,
        icon: <CloudIcon/>,
    };

    state = {
        auth: true,
        anchorEl: null,
    };

    handleChange = (event, checked) => {
        this.setState({auth: checked});
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };


    render() {
        const {children, label, icon, logout, translate} = this.props;
        if (!logout && !children) return null;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);

        return (
            <div>
                <Tooltip title=''>
                    <IconButton
                        aria-label={label && translate(label, {_: label})}
                        aria-owns={open ? 'menu-appbar' : null}
                        aria-haspopup={true}
                        color="inherit"
                        onClick={this.handleMenu}
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    {Children.map(children, MenuItemSwitchAdmin =>
                        cloneElement(MenuItemSwitchAdmin, {
                            onClick: this.handleClose,
                        })
                    )}
                </Menu>
            </div>
        );
    }
}

export default translate(AppBarMenu);
