import Api from './Api';

/**
 * Handles a single file upload.
 * Uploads the file with the API or preserves the existing then returns the override data.
 *
 * @param type
 * @param data
 * @param key
 */
async function handleSingleFile(type, data, key) {
    if ('file' !== type && 'image' !== type) {
        throw new Error('The type can only be "file" or "image".');
    }

    if (data[key].rawFile instanceof File) {
        // New file, proceed to  upload
        if ('file' === type) {
            return Api.uploadFile(data[key])
                .then(uploadedFile => {
                    return uploadedFile.json.id;
                });
        } else {
            return Api.uploadImage(data[key])
                .then(uploadedFile => {
                    return uploadedFile.json.id;
                });
        }
    } else {
        // Same file, just preserve it
        return data[key].id;
    }
}

/**
 * Handles multiple files upload.
 * Uploads files with the API and preserves existings then returns the override data.
 *
 * @param type
 * @param data
 * @param key
 * @param previousFiles
 */
async function handleMultipleFiles(type, data, key, previousFiles) {
    if ('file' !== type && 'image' !== type) {
        throw new Error('The type can only be "file" or "image".');
    }

    const oldFiles = data[key].filter(p => !(p.rawFile instanceof File)).map(picture => picture.id);
    const newFiles = data[key].filter(p => p.rawFile instanceof File);

    if (newFiles) {
        // New files added
        if ('file' === type) {
            return Promise.all(newFiles.map(Api.uploadFile))
                .then(uploadedFiles => uploadedFiles.map(file => file.json.id))
                .then(transformedNewFiles => {
                    return sortFiles([...oldFiles, ...transformedNewFiles], previousFiles);
                });
        } else {
            return Promise.all(newFiles.map(Api.uploadImage))
                .then(uploadedFiles => uploadedFiles.map(file => file.json.id))
                .then(transformedNewFiles => {
                    return sortFiles([...oldFiles, ...transformedNewFiles], previousFiles);
                });
        }
    } else {
        // No new file
        return sortFiles([...oldFiles], previousFiles);
    }
}

/**
 * Ré-ordonne les images par rapport à ce que le drag'n'drop aura autorisé.
 *
 * @param files
 * @param previousFiles
 * @returns {[]}
 */
const sortFiles = (files, previousFiles) => {
    if (!previousFiles) {
        return files;
    }

    const idOrder = previousFiles.map(image => image.id);
    const newOrder = [];

    // Ordonnement des anciennes images seulement
    for (const id of idOrder) {
        const image = files.find(imageId => imageId === id);

        if (image) {
            newOrder.push(image);
        }
    }

    // Injection des nouvelles images à la fin
    for (const file of files) {
        if (!newOrder.find(id => id === file)) {
            newOrder.push(file);
        }
    }

    return newOrder;
};

/**
 * FConvert uploaded image in base 64 and attach it to the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => async (type, resource, params) => {
    if (('UPDATE' === type || 'CREATE' === type) && ('annonces' === resource || 'modeles' === resource)) {
        if (params.data.fichierCatalogue) {
            params.data.fichierCatalogue = await handleSingleFile('file', params.data, 'fichierCatalogue');
        }

        if (params.data.annonceImage) {
            params.data.annonceImage = await handleMultipleFiles(
                'image',
                params.data,
                'annonceImage',
                params.previousData && params.previousData.annonceImage ?
                    params.previousData.annonceImage :
                    undefined
            );
        }
    }

    if (('UPDATE' === type || 'CREATE' === type) && 'amenagements' === resource) {
        if (params.data.media) {
            params.data.media = await handleSingleFile('image', params.data, 'media');
        }
    }

    return requestHandler(type, resource, {
        ...params,
        data: {
            ...params.data,
        },
    });
};

export default addUploadFeature;
