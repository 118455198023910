import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SelectField from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {userLogin, withTranslate} from 'ra-core';
import Parameters from '../lib/Parameters';

const styles = ({spacing}) =>
    createStyles({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: spacing.unit,
        },
    });

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
                         meta: {touched, error} = {touched: false, error: ''}, // eslint-disable-line react/prop-types
                         input: {...inputProps}, // eslint-disable-line react/prop-types
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const renderSelectField = ({
                               meta: {touched, error} = {touched: false, error: ''}, // eslint-disable-line react/prop-types
                               input: {...inputProps},
                               label,
                               htmlFor,
                               ...props
                           }) => {
    if ('' === inputProps.value) {
        inputProps.onChange('AVIS_FRANCE');
    }

    return (
        <FormControl fullWidth error={!!(touched && error)}>
            <InputLabel htmlFor={htmlFor}>{label}</InputLabel>
            <SelectField
                error={!!(touched && error)}
                // onChange={(event, index, value) => input.onChange(value)}
                {...inputProps}
                onChange={e => inputProps.onChange(e.target.value)}
                {...props}

            />
            {touched && error &&
            <FormHelperText>{error}</FormHelperText>
            }
        </FormControl>
    );
};

const login = (auth, dispatch, {redirectTo}) =>
    dispatch(userLogin(auth, redirectTo));

const availableApis = Parameters.getAvailableApis();

const BaseLoginForm = ({
                           classes,
                           isLoading,
                           handleSubmit,
                           translate,
                       }) => (
    <form onSubmit={handleSubmit(login)}>
        <div className={classes.form}>
            <div className={classes.input}>
                <Field
                    id="country"
                    name="country"
                    htmlFor="country"
                    component={renderSelectField}
                    label={translate('app.login.site.label')}
                    disabled={isLoading}
                >
                    {Object.keys(availableApis).map(site_code => (
                        <MenuItem value={site_code} key={site_code}>{translate(`app.login.site.${site_code}`)}</MenuItem>
                    ))}
                </Field>
            </div>
            <div className={classes.input}>
                <Field
                    id="username"
                    name="username"
                    component={renderInput}
                    label={translate('ra.auth.username')}
                    disabled={isLoading}
                />
            </div>
            <div className={classes.input}>
                <Field
                    id="password"
                    name="password"
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={isLoading}
                />
            </div>
        </div>
        <CardActions>
            <Button
                variant="raised"
                type="submit"
                color="primary"
                disabled={isLoading}
                className={classes.button}
            >
                {isLoading && (
                    <CircularProgress
                        className={classes.icon}
                        size={18}
                        thickness={2}
                    />
                )}
                {translate('ra.auth.sign_in')}
            </Button>
        </CardActions>
    </form>
);

const mapStateToProps = state => ({
    isLoading: state.admin.loading > 0,
});

const enhance = compose(
    withStyles(styles),
    withTranslate,
    connect(mapStateToProps),
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {country: '', username: '', password: ''};
            const {translate} = props;
            if (!values.country) {
                errors.country = translate('ra.validation.required');
            }
            if (!values.username) {
                errors.username = translate('ra.validation.required');
            }
            if (!values.password) {
                errors.password = translate('ra.validation.required');
            }
            return errors;
        },
    })
);

const LoginForm = enhance(BaseLoginForm);

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginForm;
