import React from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
} from 'react-admin';
import CustomPagination from '../../Theming/CustomElements/CustomPagination';
import PublishedModel from '../../components/PublishedModel';

const ModelsFilters = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={999} source="profil" reference="profiles" sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput perPage={999} source="marque" reference="marques" sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="status" choices={[
            { id: true, name: 'app.models.status.true' },
            { id: false, name: 'app.models.status.false' },
        ]} />
    </Filter>
);

const ModelList = props => (
    <List exporter={false} perPage={25} pagination={<CustomPagination />} filters={<ModelsFilters />}
          sort={{ field: 'reference', order: 'ASC' }} {...props}>
        <Datagrid rowClick="edit">
            <ReferenceField source="profil" reference="profiles" linkType={false} sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="marque" reference="marques" linkType={false} sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="reference" sortable={false} />
            <ReferenceField source="finition" reference="finitions" linkType={false} sortable={false} allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <PublishedModel source="status" sortable={true} />
            <EditButton />
        </Datagrid>
    </List>
);

export default ModelList;
