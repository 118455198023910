import React from 'react';
import { BooleanInput, FormDataConsumer, Query } from 'react-admin';

const AdsShowSimulator = () => {
    return (
        <FormDataConsumer>
            {({ formData }) => {
                if (!formData || !formData.concession) {
                    return null;
                }

                return (
                    <Query type="GET_ONE" resource="concessions" payload={{ id: formData.concession }}>
                        {({ data, loading, error }) => {
                            if (loading || error || !data || !data.useFunding) {
                                return null;
                            }

                            return <BooleanInput source="showSimulator" />;
                        }}
                    </Query>
                );
            }}
        </FormDataConsumer>
    );
};

export default AdsShowSimulator;
