import React from 'react';
import {Datagrid, EditButton, List, TextField} from 'react-admin';
import CustomPagination from "../../Theming/CustomElements/CustomPagination";

const ConcessionList = props => (
    <List perPage={25} pagination={<CustomPagination />} exporter={false} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="nom"/>
            <TextField source="villeRattachement"/>
            <TextField source="emailContact"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export default ConcessionList;
