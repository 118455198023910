import React, { useState } from 'react';
import {
    Create,
    FileField,
    FileInput,
    FormDataConsumer,
    FormTab,
    GET_ONE,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    showNotification,
    TabbedForm,
    TextInput,
    Toolbar,
    translate,
} from 'react-admin';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import WysiwygInput from '../../components/WysiwygInput';
import SaveAndSetStatusButton from '../../components/SaveAndSetStatusButton';
import ReferenceCheckboxArrayInput from '../../components/ReferenceCheckboxArrayInput';
import AdsShowSimulator from '../../components/AdsShowSimulator';
import ModelOptionField from '../../components/ModelOptionField';
import customDataProvider from '../../lib/hydra/hydraClient';
import resources from '../../lib/hydra/resources';

const dataProvider = customDataProvider({ resources });
const validateField = required();
const unwantedProperties = ['id', 'reference'];

const AdCreate = ({ translate: t, showNotification, ...props }) => {
    const [tabsDisabled, setTabsDisabled] = useState(true);
    const [defaultValues, setDefaultValues] = useState({});
    const [model, setModel] = useState();
    const onDropRejected = () => {
        showNotification(t('app.ads.errors.fileTooBig'));
    };

    const loadModel = () => {
        dataProvider(GET_ONE, 'modeles', { id: model, noCache: true })
            .then(({ data }) => {
                for (const key in data) {
                    if (!data.hasOwnProperty(key)) {
                        continue;
                    }

                    if ('@' === key[0] || unwantedProperties.includes(key)) {
                        delete data[key];
                    }
                }

                setDefaultValues(data);

                setTabsDisabled(false);
            });
    };

    const handleChange = record => {
        if (model || !tabsDisabled || !record.modele || 2 !== Object.keys(record).length) {
            return;
        }

        setModel(record.modele);
    };

    const PostCreateToolbar = ({ tabsDisabled, ...props }) => (
        <Toolbar {...props}>
            {tabsDisabled &&
            <>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={loadModel}
                    disabled={!model}
                >
                    {t('app.ads.actions.create_new_ad')}
                </Button>
                <Button
                    variant="flat"
                    component={Link}
                    to="/modeles/ask-new"
                >
                    {t('app.ads.actions.ask_new_model')}
                </Button>
            </>
            }
            {!tabsDisabled &&
            <>
                <SaveAndSetStatusButton
                    status="pending"
                    crudType="create"
                    label="app.ads.actions.save_and_publish"
                    redirect={false}
                    submitOnEnter={false}
                    {...props}
                />
                <SaveAndSetStatusButton
                    status="draft"
                    crudType="create"
                    label="app.ads.actions.save_and_draft"
                    redirect="list"
                    submitOnEnter={true}
                    variant="flat"
                    {...props}
                />
            </>
            }
        </Toolbar>
    );

    return (
        <Create {...props}>
            <TabbedForm
                submitOnEnter={false}
                redirect="list"
                toolbar={<PostCreateToolbar tabsDisabled={tabsDisabled} />}
                onChange={handleChange}
                defaultValue={defaultValues}
            >
                <FormTab label={t('app.ads.tabs.model')}>
                    <ReferenceInput perPage={999} source="marque" reference="marques" sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.marque &&
                            <ReferenceInput
                                perPage={999}
                                source="modele"
                                reference="modeles"
                                validate={validateField}
                                filter={{ marque: formData.marque, status: true }}
                                sort={{ field: 'reference', order: 'ASC' }}
                                {...rest}
                            >
                                <SelectInput optionText={<ModelOptionField />} />
                            </ReferenceInput>
                        }
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={t('app.ads.tabs.informations')} disabled={tabsDisabled}>
                    <ReferenceInput perPage={999} source="profil" reference="profiles" disabled sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={999} source="usage" reference="usages" disabled sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.marque &&
                            <ReferenceInput
                                disabled
                                perPage={999}
                                source="finition"
                                reference="finitions"
                                filter={{ brand: formData.marque }}
                                sort={{ field: 'name', order: 'ASC' }}
                                {...rest}
                            >
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        }
                    </FormDataConsumer>
                    <ReferenceInput perPage={999} source="porteur" reference="porteurs" sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceArrayInput perPage={999} source="amenagements" reference="amenagements" validate={validateField} sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                    <NumberInput source="nbPlaceCarteGrise" validate={validateField} />
                    <NumberInput source="nbPlaceCouchage" validate={validateField} />
                    <NumberInput source="nbPlaceRepas" validate={validateField} />
                </FormTab>
                <FormTab label={t('app.ads.tabs.ad')} disabled={tabsDisabled}>
                    <WysiwygInput source="description" />
                    <NumberInput source="annee" validate={validateField} />
                    <NumberInput source="kilometrage" validate={validateField} />
                    <TextInput
                        source="reference" label={'resources.annonces.fields.internReference'}
                        helperText={t('resources.annonces.fields.internReferenceHelper')}
                    />
                    <TextInput source="chevauxDin" validate={validateField} />
                    <TextInput source="chevauxFiscaux" validate={validateField} />
                    <ReferenceInput perPage={999} source="boiteVitesse" reference="boite_vitesses" validate={validateField} sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={999} source="energie" reference="energies" validate={validateField} sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={999} source="concession" reference="concessions" validate={validateField} sort={{
                        field: 'nom',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="nom" />
                    </ReferenceInput>
                    <TextInput source="garantie" validate={validateField} />
                    <NumberInput source="prix" validate={validateField} />
                    <AdsShowSimulator />
                </FormTab>
                <FormTab label={t('app.ads.tabs.weight')} disabled={tabsDisabled}>
                    <NumberInput source="chargeUtile" validate={validateField} />
                    <NumberInput source="masseMaxi" validate={validateField} />
                </FormTab>
                <FormTab label={t('app.ads.tabs.options')} disabled={tabsDisabled}>
                    <ReferenceCheckboxArrayInput perPage={999} source="options" reference="options" />
                </FormTab>
                <FormTab label={t('app.ads.tabs.media')} disabled={tabsDisabled}>
                    <ImageInput source="annonceImage" accept="image/*" multiple={true} validate={validateField}
                                maxSize={2097152}
                                options={{ onDropRejected: () => onDropRejected() }}>
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <FileInput source="fichierCatalogue" accept="application/pdf"
                               maxSize={2097152}
                               options={{ onDropRejected: () => onDropRejected() }}>
                        <FileField source="src" title="title" />
                    </FileInput>
                    <TextInput source="urlVisite360" />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default connect(null, {
    showNotification,
})(translate(AdCreate));
