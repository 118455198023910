import React, { Component } from 'react';
import RichTextInput from 'ra-input-rich-text';
import { FormLabel } from '@material-ui/core';
import compose from 'recompose/compose';
import { translate } from 'react-admin';

class WysiwygInput extends Component {
    render() {
        const { source, resource, translate } = this.props;

        return (
            <>
                <FormLabel
                    style={{
                        fontSize: '.75rem',
                    }}
                >
                    {translate(`resources.${resource}.fields.${source}`)}
                </FormLabel>
                <RichTextInput
                    {...this.props}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'link'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                    ]}
                />
            </>
        );
    }
}

export default compose(translate)(WysiwygInput);
