import { fetchUtils } from 'react-admin';
import Parameters from './Parameters';

class Api {
    #APIS;
    #httpClient;

    constructor() {
        this.#APIS = Parameters.getAvailableApis();

        this.#httpClient = (url, options = {}) => {
            if (!options.headers) {
                options.headers = new Headers({ Accept: 'application/json' });
            }

            // Add JWT token
            options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);

            // Override API country if needed
            const apiCountry = options.country || localStorage.getItem('code_site');

            return fetchUtils.fetchJson(this.#APIS[apiCountry] + url, options);
        };
    }

    /**
     * Log un utilisateur.
     *
     * @param username
     * @param password
     * @param country
     * @returns {*}
     */
    login = ({ username, password, country }) => {
        return this.#httpClient('/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            country,
        }).then(({ json }) => json);
    };

    /**
     * Switch the admin account after changing API provider.
     *
     * @param country
     * @returns {Promise<any | never>}
     */
    switchAdmin = country => {
        return this.#httpClient('/login/check-admin-key', {
            method: 'POST',
            body: JSON.stringify({ 'token': localStorage.getItem('token') }),
            country,
        }).then(({ json }) => json);
    };

    /**
     * Upload une image via l'API de Sonata.
     *
     * @param file
     * @returns {*}
     */
    uploadImage = file => {
        const formData = new FormData();

        formData.append('name', file.title);
        formData.append('enabled', '1');
        formData.append('binaryContent', file.rawFile);

        return this.#httpClient('/sonata/providers/sonata.media.provider.image/media.json', {
            method: 'POST',
            body: formData
        });
    };

    /**
     * Upload un fichier via l'API de Sonata.
     *
     * @param file
     * @returns {*}
     */
    uploadFile = file => {
        const formData = new FormData();

        formData.append('name', file.title);
        formData.append('enabled', '1');
        formData.append('binaryContent', file.rawFile);

        return this.#httpClient('/sonata/providers/sonata.media.provider.file/media.json', {
            method: 'POST',
            body: formData
        });
    };

    /**
     * Upload un fichier d'import.
     *
     * @param type
     * @param file
     * @returns {*}
     */
    uploadImportFile = (type, file) => {
        const formData = new FormData();

        formData.append('type', type);
        formData.append('file', file);

        return this.#httpClient('/imports/upload', {
            method: 'POST',
            body: formData
        }).then(({ json }) => json);
    };

    /**
     * Modère une annonce (acceptation / refus).
     *
     * @param id
     * @param moderation
     * @param reason
     * @returns {Promise<any | never>}
     */
    moderateAd = (id, moderation, reason) => {
        const idParts = id.split('/');
        const apiId = parseInt(idParts[idParts.length - 1], 10);

        return this.#httpClient(`/annonces/${apiId}/${moderation}`, {
            method: 'POST',
            body: JSON.stringify({ comment: reason })
        }).then(({ json }) => json);
    };

    /**
     * Demande la création d'un nouveau modèle.
     *
     * @param brand
     * @param profil
     * @param reference
     * @returns {*}
     */
    askNewModel = (
        brand,
        profil,
        reference,
        nbPlaceCarteGrise,
        nbPlaceCouchage,
        nbPlaceRepas,
        amenagement,
        usage,
        chargeUtile,
        masseMaxi,
        longueur,
        largeur,
        hauteur
    ) => {
        return this.#httpClient('/modeles/ask-new', {
            method: 'POST',
            body: JSON.stringify({
                brand,
                profil,
                reference,
                nbPlaceCarteGrise,
                nbPlaceCouchage,
                nbPlaceRepas,
                amenagement,
                usage,
                chargeUtile,
                masseMaxi,
                longueur,
                largeur,
                hauteur
            })
        }).then(({ json }) => json);
    };

    /**
     * Demande d'envoie d'email (validation | echec).
     *
     * @param recipient
     * @param subject
     * @param content
     * @returns {Promise<any | never>}
     */
    sendBaseEmail = (recipient, subject, content) => {
        return this.#httpClient(`/mail/send`, {
            method: 'POST',
            body: JSON.stringify({ recipient: recipient, subject: subject, content: content })
        }).then(({ json }) => json);
    };

    getDashboardData = () => {
        return this.#httpClient('/dashboard', {
            method: 'GET',
        }).then(({ json }) => json);
    };

    updateImageOrder = (record, image) => {
        if (image === []) {
            return;
        }
        const idParts = record.id.split('/');
        const apiId = parseInt(idParts[idParts.length - 1], 10);
        return this.#httpClient(`/annonces/image-order/${apiId}`, {
            method: 'POST',
            body: JSON.stringify({image: image})
        }).then(({ json }) => json)
    };

    getImagesUrlById = (images) => {
        if (images === []) {
            return;
        }

        return this.#httpClient(`/annonces/image-url-by-id/`, {
            method: 'POST',
            body: JSON.stringify({images: images})
        }).then(({ json }) => json)
    }

    getOptionCategories = () => {
        return this.#httpClient(`/annonces/get-option-categories/`, {
            method: 'POST',
        }).then(({ json }) => json)
    }
}

export default new Api();
