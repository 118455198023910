import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceArrayInput } from 'react-admin';
import OptionsCheckboxGroupInput from './OptionsCheckboxGroupInput';

const ReferenceCheckboxArrayInput = ({ classes, checkboxArrayProps, ...rest }) => (
    <ReferenceArrayInput {...rest} className="full-width" >
        <OptionsCheckboxGroupInput {...checkboxArrayProps} />
    </ReferenceArrayInput>
);

ReferenceCheckboxArrayInput.propTypes = {
    checkboxArrayProps: PropTypes.object,
};

export default ReferenceCheckboxArrayInput;
