import React from 'react';
import {
    ArrayInput,
    BooleanInput,
    Edit,
    FormTab,
    NumberInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    translate
} from 'react-admin';
import Utils from '../../lib/Utils';

const validateField = required();

const ConcessionTitle = translate(({ record, translate: t }) => {
    return <span>{t('app.concessions.edit.title', { name: record.nom })}</span>;
});

const ConcessionEdit = props => (
    <Edit title={<ConcessionTitle />} undoable={false} {...props}>
        <TabbedForm>
            <FormTab label="General infos">
                <TextInput source="nom" validate={validateField} />
                <TextInput source="villeRattachement" validate={validateField} />
                <TextInput source="emailContact" type="email" validate={validateField} />
                <TextInput source="phoneContactGeneral" validate={validateField} />
                <TextInput source="nameContactAnnonce" />
                <TextInput source="phoneContactAnnonce" validate={validateField} />
                <NumberInput source="geoPosition.latitude" validate={validateField} />
                <NumberInput source="geoPosition.longitude" validate={validateField} />
                <BooleanInput source="useFunding" />
            </FormTab>
            <FormTab label="Adresse">
                <TextInput source="adresse.address1" validate={validateField} />
                <TextInput source="adresse.address2" />
                <TextInput source="adresse.postCode" validate={validateField} />
                <TextInput source="adresse.city" validate={validateField} />
            </FormTab>
            <FormTab label="Contact">
                <ArrayInput source="destinatairesFormAchat.to" validate={Utils.validateRequiredStringArray}>
                    <SimpleFormIterator>
                        <TextInput format={Utils.formatRecordStringArrayInput} label="resources.concessions.fields.email" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="destinatairesFormAchat.cc">
                    <SimpleFormIterator>
                        <TextInput format={Utils.formatRecordStringArrayInput} label="resources.concessions.fields.email" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ConcessionEdit;
