import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { translate } from 'react-admin';

const DEFAULT_COLOR = '#606368';
const chipColors = {
    true: "#00a65a",
    false: "#f87600",
};

const PublishedModel = ({ translate: t, source, record = {} }) => {
    const label = t(`app.models.status.${record.status}`);

    return <Chip
        label={label}
        style={{
            backgroundColor: chipColors[record.status] || DEFAULT_COLOR,
            color: '#fff'
        }}
    />;
};

PublishedModel.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default translate(PublishedModel);
