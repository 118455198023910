import React from 'react';
import {Create, required, SimpleForm, TextInput} from 'react-admin';

const validateField = required();

const EnergyCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField}/>
        </SimpleForm>
    </Create>
);

export default EnergyCreate;
