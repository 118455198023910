import React from 'react';
import { Datagrid, EditButton, List, ReferenceField, TextField } from 'react-admin';
import CustomPagination from '../../Theming/CustomElements/CustomPagination';

const FinitionList = props => (
    <List exporter={false} perPage={25} pagination={<CustomPagination />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ReferenceField source="brand" reference="marques" linkType={false} sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="profile" reference="profiles" linkType={false} sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);

export default FinitionList;
