import React from 'react';
import { Create, ImageField, ImageInput, required, SimpleForm, TextInput } from 'react-admin';

const validateField = required();

const AmenagementCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField} />
            <ImageInput source="media" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);

export default AmenagementCreate;
