import React from 'react';
import {
    BooleanField,
    BooleanInput,
    DateField,
    Edit,
    EmailField,
    ReferenceField,
    SelectInput,
    SimpleForm,
    TextField,
    translate,
} from 'react-admin';
import ModelOptionField from '../../components/ModelOptionField';

const AchatFormResultTitle = translate(({ record, translate: t }) => {
    return <span>{t('app.achat_contact.edit.title', { email: record.email })}</span>;
});

const AchatFormResultEdit = props => (
    <Edit title={<AchatFormResultTitle />} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <EmailField source="email" />
            <TextField source="civilite" />
            <TextField source="prenom" />
            <TextField source="nom" />
            <TextField source="telephone" />
            <TextField source="codePostal" />
            <DateField source="createdAt" />
            <TextField source="message" />
            <BooleanField source="souhaiteEssai" />
            <ReferenceField source="annonce" reference="annonces" label="app.achat_contact.fields.concession">
                <ReferenceField source="concession" reference="concessions" linkType={false}>
                    <TextField source="nom" />
                </ReferenceField>
            </ReferenceField>
            <ReferenceField source="annonce" reference="annonces" label="app.achat_contact.fields.modele">
                <ReferenceField source="modele" reference="modeles" linkType={false}>
                    <SelectInput optionText={<ModelOptionField />} />
                </ReferenceField>
            </ReferenceField>
            <BooleanInput source="traite" />
        </SimpleForm>
    </Edit>
);

export default AchatFormResultEdit;
