import React from 'react';
import { Route } from 'react-router-dom';
import GenerateResources from './components/GenerateResources';
import { ModelAskNew } from './resources/Models';
import { ImportsList } from './resources/Imports';

const routes = [
    <Route exact path="/modeles/ask-new" component={ModelAskNew} />,
    <Route exact path="/imports" component={ImportsList} />,
];

if (process.env.NODE_ENV === 'development') {
    routes.push(<Route exact path="/generate-resources" component={GenerateResources} />);
}

export default routes;
