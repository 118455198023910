import React from 'react';
import { Edit, ReferenceInput, required, SelectInput, SimpleForm, TextInput, translate } from 'react-admin';

const validateField = required();

const FinitionTitle = translate(({ record, translate: t }) => {
    return <span>{t('app.finitions.edit.title', { name: record.name })}</span>;
});

const FinitionEdit = props => (
    <Edit title={<FinitionTitle />} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateField} />
            <ReferenceInput perPage={999} source="brand" reference="marques" validate={validateField}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput perPage={999} source="profile" reference="profiles" validate={validateField}>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default FinitionEdit;
