import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { translate } from 'react-admin';

const DEFAULT_COLOR = '#606368';
const chipColors = {
    draft: "#606368",
    published: "#00a65a",
    unpublished: "#f44336",
    refused: "#f44336",
    archived: "#606368",
    pending: "#f87600",
};

const PublishedField = ({ translate: t, source, record = {} }) => {
    const label = t(`app.ads.status.${record.status}`);

    return <Chip
        label={label}
        style={{
            backgroundColor: chipColors[record.status] || DEFAULT_COLOR,
            color: '#fff'
        }}
    />;
};

PublishedField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default translate(PublishedField);
