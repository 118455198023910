import {Layout} from 'react-admin';
import React from "react";
import AppBar from "./CustomElements/AppBar";
import AppBarMenu from "./CustomElements/AppBarMenu";
import {MenuItemSwitchAdmin} from "./CustomElements/MenuItemSwitchAdmin";

import Menu from './Menu';

const isAdmin = function () {
    return localStorage.getItem('role') === 'ROLE_SUPER_ADMIN' || localStorage.getItem('role') === 'ROLE_ADMIN';
};

const CustomUserMenu = props =>
    <AppBarMenu {...props}>
        <MenuItemSwitchAdmin primaryText={"🇫🇷 occasions.pilote.fr"} code_site={"AVIS_FRANCE"}/>
        {/*<MenuItemSwitchAdmin primaryText={"🇬🇧 Avis.co.uk"} code_site={"avis_england"}/>*/}
    </AppBarMenu>
;

let CustomAppBar = props => !isAdmin() ?
    <AppBar {...props} />
    : <AppBar {...props} appBarMenu={<CustomUserMenu/>}/>;

const CustomLayout = props =>
    <Layout {...props} appBar={CustomAppBar} menu={Menu}/>
    ;

export default CustomLayout;
