import React from 'react';
import {Datagrid, EditButton, List, ReferenceField, TextField} from 'react-admin';
import CustomPagination from "../../Theming/CustomElements/CustomPagination";

const OptionList = props => (
    <List exporter={false} perPage={25} pagination={<CustomPagination />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <ReferenceField source="category" reference="option_categories" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <EditButton/>
        </Datagrid>
    </List>
);

export default OptionList;
