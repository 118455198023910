import React from 'react';
import {
    BooleanField,
    BooleanInput,
    BulkDeleteButton,
    Datagrid,
    DateField,
    EditButton,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
} from 'react-admin';
import CustomPagination from '../../Theming/CustomElements/CustomPagination';
import ModelOptionField from '../../components/ModelOptionField';
import BulkDoneButton from '../../components/BulkDoneButton';

const AdFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={999} source="concession" reference="concessions" allowEmpty>
            <SelectInput optionText="nom" />
        </ReferenceInput>
        <BooleanInput source="traite" />
        <ReferenceInput perPage={999} source="modele" reference="modeles" allowEmpty>
            <SelectInput optionText={<ModelOptionField />} />
        </ReferenceInput>
    </Filter>
);

const BulkActionButtons = props => (
    <>
        <BulkDoneButton {...props} />
        <BulkDeleteButton {...props} />
    </>
);

const AchatFormResultList = props => (
    <List exporter={false} perPage={25} pagination={<CustomPagination />} filters={<AdFilter />}
          bulkActionButtons={<BulkActionButtons />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="nom" sortable={false} />
            <TextField source="prenom" sortable={false} />
            <TextField source="email" sortable={false} />
            <DateField source="createdAt" sortable={false} />
            <ReferenceField source="annonce" reference="annonces" label="app.achat_contact.fields.concession" sortable={false}>
                <ReferenceField source="concession" reference="concessions" linkType={false}>
                    <TextField source="nom" />
                </ReferenceField>
            </ReferenceField>
            <ReferenceField source="annonce" reference="annonces" label="app.achat_contact.fields.modele" sortable={false}>
                <ReferenceField source="modele" reference="modeles" linkType={false}>
                    <ModelOptionField />
                </ReferenceField>
            </ReferenceField>
            <BooleanField source="traite" sortable={false} />
            <EditButton />
        </Datagrid>
    </List>
);

export default AchatFormResultList;
