import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    Edit,
    FileField,
    FileInput,
    FormDataConsumer,
    FormTab,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SaveButton,
    SelectArrayInput,
    SelectInput,
    showNotification,
    TabbedForm,
    TextInput,
    Toolbar,
    translate,
} from 'react-admin';
import { push as pushAction } from 'react-router-redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconApprove from '@material-ui/icons/ThumbUp';
import IconDecline from '@material-ui/icons/ThumbDown';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import { DateTimeInput } from 'react-admin-date-inputs';
import Sortable from 'sortablejs';
import WysiwygInput from '../../components/WysiwygInput';
import Api from '../../lib/Api';
import Utils from '../../lib/Utils';
import SaveAndSetStatusButton from '../../components/SaveAndSetStatusButton';
import ReferenceCheckboxArrayInput from '../../components/ReferenceCheckboxArrayInput';
import AdsShowSimulator from '../../components/AdsShowSimulator';
import ModelOptionField from '../../components/ModelOptionField';

const styles = theme => ({
    card: {
        marginLeft: theme.spacing.unit,
    },
    root: {
        flexGrow: 1,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    body: {
        marginBottom: theme.spacing.unit,
    },
});

const validateField = required();
const validateDatePublish = [Utils.validateDate()];
const validateDateUnPublish = Utils.validateDate();

const AdTitle = translate(({ record, translate: t }) => {
    return <span>{t('app.ads.edit.title', { reference: record.reference || 'N/A' })}</span>;
});

function formatLogo(value) {
    if (value) {
        for (let i = 0; i < value.length; i++) {
            // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
            if (!value[i] || typeof value[i] === 'string') {
                value[i] = { id: value[i] };
            }
        }
    }

    return value;
}

function detectItems(record) {
    let alreadyBind = false;

    // Ajout de l'event listener
    document.getElementsByTagName('form')[0].addEventListener('mouseover', function () {
        let hash = window.location.hash;
        let tab = hash.charAt(hash.length - 2) + hash.charAt(hash.length - 1);

        if (tab !== '/4') {
            alreadyBind = false;
        } else {
            if (!alreadyBind) {
                let previews = document.getElementsByClassName('previews');

                if (tab === '/4') {
                    for (let i = 0; i < previews.length; i++) {
                        alreadyBind = true;
                        previews[i].classList.add('drag-sort-enable');
                        Sortable.create(previews[i], {
                            animation: 0,
                            filter: (event, element) => {
                                // Si l'élément contient une source qui commence par "blob:", c'est que c'est une
                                // nouvelle image. On interdit donc le drag'n'drop dessus.
                                return null !== element.querySelector('[src^="blob:"]');
                            },
                            onEnd: event => {
                                const images = event.to.querySelectorAll('img');
                                const srcOrder = [...images].map(image => image.src);
                                const newOrder = [];
                                const actualImages = record.annonceImage;

                                // Calcul du nouvel ordre des images
                                for (const src of srcOrder) {
                                    const image = actualImages.find(image => image.src === src);

                                    if (image) {
                                        newOrder.push(image);
                                    }
                                }

                                record.annonceImage = newOrder;
                            },
                        });
                    }
                }
            }
        }
    });
}

const AdAside = connect(null, {
    showNotification,
    push: pushAction,
})(withStyles(styles)(translate(({
                                     classes,
                                     record,
                                     translate: t,
                                     showNotification,
                                     push,
                                     permissions,
                                 }) => {
    const [moderationText, setModerationText] = useState('');
    const [moderationTextError, setModerationTextError] = useState(false);
    const [moderate, setModerate] = useState(null);
    const isAdmin = Utils.isAdmin(permissions);

    useEffect(() => {
        if (isAdmin && null !== moderate) {
            if ('decline' === moderate && !moderationText) {
                setModerationTextError(true);
            } else {
                Api.moderateAd(record.id, moderate, moderationText)
                    .then(() => {
                        showNotification(`app.ads.moderation.${moderate}`);

                        push('/annonces');
                    })
                    .catch(() => showNotification('app.ads.moderation.error', 'error'));
            }
        }

        return () => setModerate(null);
    }, [isAdmin, moderate, moderationText, record, showNotification, push]);

    useLayoutEffect(() => {
        let previews = document.getElementsByClassName('previews');

        if (previews.length > 0) {
            const images = record.annonceImage;

            // Pas d'image, ou des images, mais avec un src
            if (!images || !images.length || (images[0] && images[0].src)) {
                detectItems(record);
            }
        }

    }, [record]);

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="title">{t('app.ads.moderation.title')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {record &&
                        <>
                            <Typography variant="subheading" className={classes.body}>
                                {t('app.ads.moderation.status', { status: t(`app.ads.status.${record.status}`) })}
                            </Typography>
                            <br />

                            {'published' === record.status &&
                            <Typography variant="body1" className={classes.body}>
                                {t('app.ads.moderation.publishDate')}<br />
                                {record.publishDate && Utils.formatDate(record.publishDate)}
                                <br /><br />
                                {t('app.ads.moderation.unpublishDate')}<br />
                                {record.unpublishDate && Utils.formatDate(record.unpublishDate)}
                                <br />
                            </Typography>
                            }

                            {record.moderationText &&
                            <Typography variant="body1" className={classes.body}>
                                {t('app.ads.moderation.moderationText')}<br />
                                {Utils.nl2br(record.moderationText)}
                                <br />
                            </Typography>
                            }

                            {isAdmin && 'pending' === record.status &&
                            <TextField
                                error={moderationTextError}
                                required
                                multiline
                                rowsMax={4}
                                id="moderationText"
                                label={t('app.ads.moderation.moderationText')}
                                helperText={t('app.ads.moderation.moderationTextHelp')}
                                onChange={e => setModerationText(e.target.value)}
                            />
                            }
                        </>
                        }
                    </Grid>
                </Grid>
            </CardContent>
            {isAdmin && record && 'pending' === record.status &&
            <CardActions>
                <Button variant="contained" color="primary" size="small" onClick={() => setModerate('approve')}>
                    {t('app.ads.moderation.btnApprove')}
                    <IconApprove className={classes.rightIcon} />
                </Button>
                <Button variant="contained" color="default" size="small" onClick={() => setModerate('decline')}>
                    {t('app.ads.moderation.btnDecline')}
                    <IconDecline className={classes.rightIcon} />
                </Button>
            </CardActions>
            }
        </Card>
    );
})));

const PostEditToolbar = props => {
    const { record: { status } } = props;

    return (
        <Toolbar {...props}>
            {/* Si en brouillon, on peut soumettre à validation ou laisser en brouillon */}
            {('draft' === status || 'unpublished' === status) &&
            <>
                <SaveAndSetStatusButton
                    status="pending"
                    crudType="update"
                    label="app.ads.actions.save_and_publish"
                    redirect={false}
                    submitOnEnter={false}
                    {...props}
                />
                < SaveAndSetStatusButton
                    status="draft"
                    crudType="update"
                    label="app.ads.actions.save_and_draft"
                    redirect="list"
                    submitOnEnter={true}
                    variant="flat"
                    {...props}
                />
            </>
            }

            {'refused' === status &&
            <>
                <SaveAndSetStatusButton
                    status="pending"
                    crudType="update"
                    label="app.ads.actions.save_and_re_publish"
                    redirect={false}
                    submitOnEnter={true}
                    variant="flat"
                    {...props}
                />
            </>
            }

            {/* Si pas en brouillon, pas dé-publiée et pas archivée, on peut sauvegarder normalement */}
            {'draft' !== status && 'unpublished' !== status && 'archived' !== status &&
            <SaveButton />
            }

            {'published' === status &&
            <>
                <SaveAndSetStatusButton
                    status="unpublished"
                    crudType="update"
                    label="app.ads.actions.save_and_unpublish"
                    redirect={false}
                    submitOnEnter={false}
                    variant="flat"
                    {...props}
                />
            </>
            }
            {'unpublished' === status &&
            <>
                <SaveAndSetStatusButton
                    status="archived"
                    crudType="update"
                    label="app.ads.actions.save_and_archive"
                    redirect={false}
                    submitOnEnter={false}
                    variant="flat"
                    {...props}
                />
            </>
            }
        </Toolbar>
    );
};

const AdEdit = ({ translate: t, permissions, showNotification, ...props }) => {
    const isAdmin = Utils.isAdmin(permissions);
    const onDropRejected = () => {
        showNotification(t('app.ads.errors.fileTooBig'));
    };

    return (
        <Edit
            title={<AdTitle />}
            undoable={false}
            aside={<AdAside permissions={permissions} />}
            {...props}
        >
            <TabbedForm
                redirect="list"
                submitOnEnter={false}
                toolbar={<PostEditToolbar />}
            >
                <FormTab label={t('app.ads.tabs.informations')}>
                    <ReferenceInput perPage={999} source="marque" reference="marques" disabled sort={{ field: 'name', order: 'ASC' }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={999} source="profil" reference="profiles" disabled sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={999} source="modele" reference="modeles" disabled sort={{
                        field: 'reference',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText={<ModelOptionField />} />
                    </ReferenceInput>
                    <ReferenceInput perPage={999} source="usage" reference="usages" disabled sort={{ field: 'name', order: 'ASC' }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.marque &&
                            <ReferenceInput
                                disabled
                                perPage={999}
                                source="finition"
                                reference="finitions"
                                filter={{ brand: formData.marque }}
                                sort={{ field: 'name', order: 'ASC' }}
                                {...rest}
                            >
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        }
                    </FormDataConsumer>
                    <ReferenceInput perPage={999} source="porteur" reference="porteurs" sort={{ field: 'name', order: 'ASC' }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceArrayInput perPage={999} source="amenagements" reference="amenagements" validate={validateField} sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                    <NumberInput source="nbPlaceCarteGrise" validate={validateField} />
                    <NumberInput source="nbPlaceCouchage" validate={validateField} />
                    <NumberInput source="nbPlaceRepas" validate={validateField} />
                    {isAdmin &&
                    <>
                        <DateTimeInput
                            source="publishDate"
                            label={t('resources.annonces.fields.publishDate')}
                            validate={validateDatePublish}
                            options={{ format: 'dd/MM/YYYY HH:mm', ampm: false, clearable: true }}
                        />
                        <DateTimeInput
                            source="unpublishDate"
                            label={t('resources.annonces.fields.unpublishDate')}
                            validate={validateDateUnPublish}
                            options={{ format: 'dd/MM/YYYY HH:mm', ampm: false, clearable: true }}
                        />
                    </>
                    }
                </FormTab>
                <FormTab label={t('app.ads.tabs.ad')}>
                    <WysiwygInput source="description" />
                    <NumberInput source="annee" validate={validateField} />
                    <NumberInput source="kilometrage" validate={validateField} />
                    <TextInput source="reference" label={'resources.annonces.fields.internReference'} helperText={t('app.ads.moderation.notDisplayed')} />
                    <TextInput source="chevauxDin" validate={validateField} />
                    <TextInput source="chevauxFiscaux" validate={validateField} />
                    <ReferenceInput perPage={999} source="boiteVitesse" reference="boite_vitesses" validate={validateField} sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={999} source="energie" reference="energies" validate={validateField} sort={{
                        field: 'name',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={999} source="concession" reference="concessions" validate={validateField} sort={{
                        field: 'nom',
                        order: 'ASC',
                    }}>
                        <SelectInput optionText="nom" />
                    </ReferenceInput>
                    <TextInput source="garantie" validate={validateField} />
                    <NumberInput source="prix" validate={validateField} />
                    <AdsShowSimulator />
                </FormTab>
                <FormTab label={t('app.ads.tabs.weight')}>
                    <NumberInput source="chargeUtile" validate={validateField} />
                    <NumberInput source="masseMaxi" validate={validateField} />
                </FormTab>
                <FormTab label={t('app.ads.tabs.options')}>
                    <ReferenceCheckboxArrayInput perPage={999} source="options" reference="options" />
                </FormTab>
                <FormTab label={t('app.ads.tabs.media')}>
                    <ImageInput
                        source="annonceImage"
                        format={formatLogo}
                        accept="image/*"
                        multiple={true}
                        validate={validateField}
                        maxSize={2097152}
                        options={{ onDropRejected: () => onDropRejected() }}
                    >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <FileInput source="fichierCatalogue" accept="application/pdf"
                               maxSize={2097152}
                               options={{ onDropRejected: () => onDropRejected() }}>
                        <FileField source="src" title="title" />
                    </FileInput>
                    <TextInput source="urlVisite360" />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
export default connect(null, {
    showNotification,
})(translate(AdEdit));
