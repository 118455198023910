import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../lib/Utils';

function populateList(numbers) {
    return numbers.map((number) =>
        <p key={number.toString()}>
            {Utils.formatRoleName(number)}
        </p>
    );
}

const SimpleArray = ({source, record = {}}) =>
        populateList(record[source])
;


SimpleArray.defaultProps = {
    addLabel: true,
    label: 'List'
};


SimpleArray.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string
};

export default SimpleArray;
