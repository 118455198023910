import React from 'react';
import { Admin, Resource } from 'react-admin';
import defaultFrenchMessages from 'ra-language-french';
import customMessages from './i18n';
import authProvider from './lib/authProvider';
import customDataProvider from './lib/hydra/hydraClient';
import Dashboard from './resources/Dashboard';
import { AdCreate, AdEdit, AdList } from './resources/Ads';
import { CarrierCreate, CarrierEdit, CarrierList } from './resources/Carriers';
import { ConcessionCreate, ConcessionEdit, ConcessionList } from './resources/Concessions';
import { BrandCreate, BrandEdit, BrandList } from './resources/Brands';
import { EnergyCreate, EnergyEdit, EnergyList } from './resources/Energies';
import { FinitionCreate, FinitionEdit, FinitionList } from './resources/Finitions';
import { GearCreate, GearEdit, GearList } from './resources/Gears';
import { OptionCreate, OptionEdit, OptionList } from './resources/Options';
import { UsageCreate, UsageEdit, UsageList } from './resources/Usages';
import { UserCreate, UserEdit, UserList } from './resources/Users';
import { AmenagementCreate, AmenagementEdit, AmenagementList } from './resources/Amenagements';
import { ModelCreate, ModelEdit, ModelList } from './resources/Models';
import { AchatFormResultEdit, AchatFormResultList } from './resources/AchatFormResult';
import { ContactFormResultEdit, ContactFormResultList } from './resources/ContactFormResult';
import {OptionCategoryList, OptionCategoryEdit, OptionCategoryCreate}  from "./resources/OptionCategories";
import LoginPage from './Login/LoginPage';
import addUploadFeature from './lib/addUploadFeature';
import addCustomFiltering from './lib/addCustomFiltering';
import Layout from './Theming/Layout';
import ApiThemeProvider from './Theming/CustomElements/ApiThemeProvider';
import resources from './lib/hydra/resources';
import customRoutes from './customRoutes';
import Utils from './lib/Utils';

import './sass/index.scss';

const messages = { fr: { ...defaultFrenchMessages, ...customMessages.fr } };
const i18nProvider = locale => messages[locale];
const uploadCapableDataProvider = addCustomFiltering(addUploadFeature(customDataProvider({ resources })));

const App = () => (
    <Admin
        appLayout={Layout}
        dashboard={Dashboard}
        authProvider={authProvider}
        loginPage={LoginPage}
        dataProvider={uploadCapableDataProvider}
        locale="fr"
        i18nProvider={i18nProvider}
        theme={ApiThemeProvider}
        customRoutes={customRoutes}
    >
        {permissions => [
            <Resource
                name="amenagements"
                list={Utils.isAdmin(permissions) ? AmenagementList : null}
                edit={Utils.isAdmin(permissions) ? AmenagementEdit : null}
                create={Utils.isAdmin(permissions) ? AmenagementCreate : null}
            />,
            <Resource name="annonces" list={AdList} edit={AdEdit} create={AdCreate} />,
            <Resource
                name="boite_vitesses"
                list={Utils.isAdmin(permissions) ? GearList : null}
                edit={Utils.isAdmin(permissions) ? GearEdit : null}
                create={Utils.isAdmin(permissions) ? GearCreate : null}
            />,
            <Resource
                name="concessions"
                list={Utils.isAdmin(permissions) ? ConcessionList : null}
                edit={Utils.isAdmin(permissions) ? ConcessionEdit : null}
                create={Utils.isAdmin(permissions) ? ConcessionCreate : null}
            />,
            <Resource
                name="contact_form_results"
                list={Utils.isAdmin(permissions) ? ContactFormResultList : null}
                edit={Utils.isAdmin(permissions) ? ContactFormResultEdit : null}
            />,
            <Resource
                name="achat_form_results"
                list={AchatFormResultList}
                edit={AchatFormResultEdit}
            />,
            <Resource
                name="energies"
                list={Utils.isAdmin(permissions) ? EnergyList : null}
                edit={Utils.isAdmin(permissions) ? EnergyEdit : null}
                create={Utils.isAdmin(permissions) ? EnergyCreate : null}
            />,
            <Resource
                name="finitions"
                list={Utils.isAdmin(permissions) ? FinitionList : null}
                edit={Utils.isAdmin(permissions) ? FinitionEdit : null}
                create={Utils.isAdmin(permissions) ? FinitionCreate : null}
            />,
            <Resource
                name="marques"
                list={Utils.isAdmin(permissions) ? BrandList : null}
                edit={Utils.isAdmin(permissions) ? BrandEdit : null}
                create={Utils.isAdmin(permissions) ? BrandCreate : null}
            />,
            <Resource
                name="modeles"
                list={Utils.isAdmin(permissions) ? ModelList : null}
                edit={Utils.isAdmin(permissions) ? ModelEdit : null}
                create={Utils.isAdmin(permissions) ? ModelCreate : null}
            />,
            <Resource
                name="options"
                list={Utils.isAdmin(permissions) ? OptionList : null}
                edit={Utils.isAdmin(permissions) ? OptionEdit : null}
                create={Utils.isAdmin(permissions) ? OptionCreate : null}
            />,
            <Resource
                name="option_categories"
                list={Utils.isAdmin(permissions) ? OptionCategoryList : null}
                edit={Utils.isAdmin(permissions) ? OptionCategoryEdit : null}
                create={Utils.isAdmin(permissions) ? OptionCategoryCreate : null}
            />,
            <Resource
                name="porteurs"
                list={Utils.isAdmin(permissions) ? CarrierList : null}
                edit={Utils.isAdmin(permissions) ? CarrierEdit : null}
                create={Utils.isAdmin(permissions) ? CarrierCreate : null}
            />,
            <Resource
                name="profiles"
            />,
            <Resource
                name="usages"
                list={Utils.isAdmin(permissions) ? UsageList : null}
                edit={Utils.isAdmin(permissions) ? UsageEdit : null}
                create={Utils.isAdmin(permissions) ? UsageCreate : null}
            />,
            <Resource
                name="users"
                list={Utils.isAdmin(permissions) ? UserList : null}
                edit={Utils.isAdmin(permissions) ? UserEdit : null}
                create={Utils.isAdmin(permissions) ? UserCreate : null}
            />,
        ]}
    </Admin>
);

export default App;
