import React, {Component} from 'react';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {withDataProvider} from 'react-admin';
import Api from "../../lib/Api";
import DashboardAdsCard from "../../Theming/CustomElements/DashboardAdsCard";
import DashboardContactCard from "../../Theming/CustomElements/DashboardContactCard";
import Utils from "../../lib/Utils";
import DashboardConcessionCard from "../../Theming/CustomElements/DashboardConcessionCard";
import BrandListCard from "../../Theming/CustomElements/BrandListCard";
import DashboardModelCard from "../../Theming/CustomElements/DashboardModelCard";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginLeft: '1em'},
    rightCol: {flex: 1, marginRight: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em', marginLeft: '1em'},
    row: {width: '100%'}
};

class Dashboard extends Component {
    state = {};

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.version !== prevProps.version) {
            this.fetchData();
        }
    }

    fetchData() {
        this.getData();
    }

    async getData() {
        const {data: allData} = await Api.getDashboardData();

        const nbTotalAnnonces = allData.nbTotalAnnonces;
        const nbPublishedAnnonces = allData.nbPublishedAnnonces;
        const nbDraftAnnonces = allData.nbDraftAnnonces;
        const nbTotalContact = allData.nbTotalContact;
        const nbPendingContact = allData.nbPendingContact;
        const brandList = allData.brandList;
        const nbPendingModels = allData.nbPendingModels;
        const nbTotalModels = allData.nbTotalModels;

        const nbConcessions = allData.nbConcessions;
        const nbUsers = allData.nbUsers;


        this.setState({
            nbTotalAnnonces,
            nbPublishedAnnonces,
            nbDraftAnnonces,
            nbTotalContact,
            nbPendingContact,
            nbConcessions,
            nbUsers,
            brandList,
            nbPendingModels,
            nbTotalModels
        })
    }

    render() {
        const {
            nbTotalAnnonces,
            nbPublishedAnnonces,
            nbDraftAnnonces,
            nbTotalContact,
            nbPendingContact,
            nbConcessions,
            nbUsers,
            brandList,
            nbPendingModels,
            nbTotalModels
        } = this.state;

        const isAdmin = Utils.isAdmin(this.props.permissions);

        return (

            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        {isAdmin ?
                            <>
                                <DashboardModelCard value={{nbPendingModels, nbTotalModels}} />
                                <DashboardAdsCard value={{
                                    nbTotalAnnonces,
                                    nbPublishedAnnonces,
                                    nbDraftAnnonces
                                }}/>
                            </>
                            :
                            <>
                                <DashboardAdsCard value={{
                                    nbTotalAnnonces,
                                    nbPublishedAnnonces,
                                    nbDraftAnnonces
                                }}/>
                                <DashboardContactCard value={{
                                    nbTotalContact,
                                    nbPendingContact
                                }} />
                            </>
                        }


                    </div>
                    <div style={styles.singleCol}>
                        <BrandListCard value={brandList} />
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>

                        {isAdmin ?
                            <>
                                <DashboardContactCard value={{
                                    nbTotalContact,
                                    nbPendingContact
                                }} />
                                <DashboardConcessionCard value={{nbConcessions, nbUsers}}/>
                            </>
                            : null
                        }
                    </div>
                </div>

            </div>

        )
    }
}

const mapStateToProps = state => ({
    version: state.admin.ui.viewVersion,
});

export default compose(
    connect(mapStateToProps),
    withDataProvider
)(Dashboard)
