import React from 'react';
import {Edit, required, SimpleForm, TextInput, translate} from 'react-admin';

const validateField = required();

const GearTitle = translate(({record, translate: t}) => {
    return <span>{t('app.gears.edit.title', {name: record.name})}</span>;
});

const GearEdit = props => (
    <Edit title={<GearTitle/>} undoable={false} {...props}>
        <SimpleForm redirect="list" validate={validateField}>
            <TextInput source="name" validate={validateField}/>
        </SimpleForm>
    </Edit>
);

export default GearEdit;
