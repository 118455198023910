import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import ImportsFieldsTable from './FieldsTable';
import ImportUploadButton from './UploadButton';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
    },
});

const ImportResourceImport = ({ classes, translate, title, fields, type }) => (
    <div className={classes.root}>
        <Grid container spacing={24}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Typography className={classes.title} component="h3" variant="headline">
                        {translate(title)}
                    </Typography>

                    <ImportsFieldsTable type={type} fields={fields} />

                    <ImportUploadButton type={type} />
                </Paper>
            </Grid>
        </Grid>
    </div>
);

ImportResourceImport.propTypes = {
    title: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    type: PropTypes.oneOf(['users', 'concessions', 'options', 'models']).isRequired,
};

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(ImportResourceImport);
