import React, { useRef, useState } from 'react';
import { Button, CircularProgress, Typography, withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import Api from '../../lib/Api';
import ImportSummary from './Summary';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
});

const isRefValid = ref => ref && ref.current && ref.current.files && 1 === ref.current.files.length;

const ImportUploadButton = ({ classes, type }) => {
    const fileRef = useRef(null);
    const [error, setError] = useState(null);
    const [summary, setSummary] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const onChange = () => {
        if (!isRefValid(fileRef)) {
            setUploadFile(null);

            return;
        }

        const file = fileRef.current.files[0];

        if (file.size > 8000000) {
            setError('Veuillez sélectionner un fichier de 8Mo ou moins.');
        } else {
            setUploadFile(file.name);
        }
    };
    const upload = () => {
        setLoading(false);

        if (!isRefValid(fileRef)) {
            setError('Veuillez sélectionner un fichier à uploader.');

            return;
        }

        setError(null);
        setSummary(null);
        setLoading(true);

        Api.uploadImportFile(type, fileRef.current.files[0])
            .then(({ success, summary, errors }) => {
                if (!success) {
                    setError(errors.join("\n"));
                } else {
                    setSummary(summary);
                }
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    };

    return (
        <>
            <p>
                <input className={classes.input} id="flat-button-file" type="file" ref={fileRef} onChange={onChange} />
                <label htmlFor="flat-button-file">
                    <Button component="span" className={classes.button} variant="contained" color="primary">
                        Choisir un fichier CSV
                    </Button>
                </label>

                {uploadFile &&
                <Button variant="contained" color="primary" onClick={upload} disabled={loading}>
                    Uploader {uploadFile}
                </Button>
                }
            </p>

            {loading && <CircularProgress />}

            {error && <Typography color="error" component="p">{error}</Typography>}

            {summary && <ImportSummary {...summary} />}
        </>
    );
};

const enhance = compose(
    withStyles(styles)
);

export default enhance(ImportUploadButton);
